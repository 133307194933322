import { destroy, get, post, put } from '.';

export function GetCommunicationQueueGroupUsers(vid: string, options = {}) {
	return get<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_group_users", options);
}

export function UpdateCommunicationQueueGroupUser(id, options = {}) {
	return put<any>("/app/api/communication_queue_group_users/" + (id || 0).toString(), options);
}

export function CreateCommunicationQueueGroupUser(options = {}) {
	return post<any>("/app/api/communication_queue_group_users", options);
}

export function DestroyCommunicationQueueGroupUser(vid: string) {
	return destroy<any>("/app/api/communication_queue_group_users/" + (vid));
}