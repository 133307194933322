export function scorecardScoreColor(pointsScored: number, pointsAvailable: number): string {

    if (pointsAvailable === 0) {
        return 'grey';
    }

    const scorePercentage = getScorecardScorePercentage(pointsScored, pointsAvailable);

    if (scorePercentage < 50) {
        return 'error'
    }
    if (scorePercentage < 80) {
        return 'warning'
    }

    return 'success';
}

export function scorecardScoreColorClass(pointsScored: number, pointsAvailable: number): string {

    return scorecardScoreColor(pointsScored, pointsAvailable) + "--text";
}

export function getScorecardScorePercentage(pointsScored: number, pointsAvailable: number): number {
    if (pointsAvailable === 0) {
        return 0;
    }

    return (pointsScored / pointsAvailable) * 100
}

export function getRankChange(currentRank: number, prevRank: number, listLength: number): number {

    if (currentRank < 1) {
        return 0;
    }

    let prevRankCalc = listLength;
    if (prevRank >= 1) {
        prevRankCalc = prevRank;
    }

    return (prevRankCalc - currentRank);
}

export function getScoreChange(currentScore: number, prevScore: number): number {
    
    return parseFloat(((currentScore || 0) - (prevScore || 0)).toFixed(2));
}
