<template lang="pug">
    v-container.mx-auto
        .text-subtitle-2.subtitle-2-padding.text-center
            | Create New Appointment

        Form(
            v-if="!isLoading"
            :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
            :create-tekion-appointment="createTekionAppointment"
            :current-campaign-customer="currentCampaignCustomer"
            :customer-vehicles="customerVehicles"
            :display-slots-view="displaySlotsView"
            :display-details-view="displayDetailsView"
            :scoped-slot="scopedSlot",
            :set-tekion-appointment-details="setTekionAppointmentDetails"
            :slot-form-object="slotFormObject",
            :show-snackbar="showSnackbar"

        )

        .text-center(v-else)
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px; margin-bottom: 100px;"
            )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import Form from '@/components/communication_center/shared/tabs/tekion/form.vue';

    // Data
    import { CreateTekionAppointment } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
            }
        },
        methods: {
            createTekionAppointment(formAppointment) {

                if (formAppointment === null || this.slotFormObject === null || this.currentCampaignCustomer === null) {
                    return;
                }

                const options = {
                    appointment_date_time: formAppointment.appointment_date_time,
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    customer_comments: formAppointment.customer_comments,
                    delivery_contact_same_as_customer: formAppointment.delivery_contact_same_as_customer,
                    delivery_contact: formAppointment.delivery_contact,
                    is_default_job: formAppointment.is_default_job,
                    jobs: [{
                        type: formAppointment.job_type,
                        tekion_opcode_ids: formAppointment.tekion_opcodes,
                    }],
                    notify_customer: formAppointment.notify_customer,
                    tekion_service_menu_id: formAppointment.tekion_service_menu_id,
                    tekion_shop_sid: this.slotFormObject.tekion_shop_sid,
                    tekion_service_advisor_sid: this.slotFormObject.tekion_service_advisor_sid,
                    tekion_transportation_type_sid: this.slotFormObject.tekion_transportation_type_sid,
                    vehicle_id: formAppointment.vehicle_id,
                    vehicle_year: formAppointment.vehicle_year,
                    vehicle_make: formAppointment.vehicle_make,
                    vehicle_model: formAppointment.vehicle_model,
                }

                this.isLoading = true;

                CreateTekionAppointment(options).then((response) => {
                    if (response?.Data?.appointment_id > 0 && !!response?.Data?.tekion_appointment) {
                        // success
                        this.showSnackbar("success", "Succesfully scheduled appointment")
                        this.setAppointmentIdForTekion(response.Data.appointment_id);
                        this.addNewAppointment(response.Data.tekion_appointment);
                        this.getCustomerVehicles();
                        this.displayDetailsView();
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                        }
                    } else {
                        let err = "Unable to schedule appointment"
                        if (response?.Error?.length > 0) {
                            err = response.Error
                        }
                        this.showSnackbar("error", err);
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                        }
                    }
                    this.isLoading = false;
                });
            },
            resetSlot() {
                this.scopedSlot = null;
                this.slotFormObject = null;
            }
        },

        computed: {
        currentUser() {
                return VolieState.currentUser();
            },

        },
        components: {
            Form,
        },
        props: {
            addNewAppointment: Function,
            convertAppointmentDateTimeFromMilliseconds: Function,
            currentCampaignCustomer: Object,
            displaySlotsView: Function,
            displayDetailsView: Function,
            getCustomerVehicles: Function,
            scopedSlot: Object,
            slotFormObject: Object,
            customerVehicles: Array,
            setTekionError: Function,
            setTekionAppointmentDetails: Function,
            setAppointmentIdForTekion: Function,
            showSnackbar: Function,
        },
        created() {
            console.log('Create component mounted with props', {
                scopedSlot: this.scopedSlot,
                slotFormObject: this.slotFormObject,
            })
        },
        destroyed() {
        }
    });
</script>

<style lang="scss" scoped>
</style>
