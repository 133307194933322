<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='750px')
        template(v-if="!hideCreateUpdateDestroyButtons" v-slot:activator='{ on }')
            v-btn.my-1.ml-1(
                v-on='on'
                color="success"
                small 
                ripple 
                outlined
                @click="init()"
            )
                v-icon mdi-plus
                | New Scorecard
        v-card.mx-lg-auto(width="100%")
            v-card-title
                span.text-h5 New Scorecard
            v-card-text
                v-container
                    span.text-h6 Select a scorecard template to score this communication.

                v-container
                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-row(v-else-if="validAvailableScorecardTemplates")
                        v-col(cols='12')
                            v-card 
                                v-card-text.pa-0
                                    template(v-for='scorecardTemplate in availableScorecardTemplates')
                                        ScorecardTemplateCard(
                                            :key='scorecardTemplate.scorecard_template_id'
                                            :scorecard-template="scorecardTemplate"
                                            :select-card="onSelectCard"
                                            :scoped-scorecard-template="scopedScorecardTemplate"
                                        )
                                        v-divider.ma-0

                    v-alert(v-else outlined type='info' dense border='left' style="margin-top: 16px;")
                        | Unable to find any scorecard templates

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false;')
                        v-icon mdi-window-close
                        | Cancel
                    v-btn.ma-2(color="success" outlined ripple :disabled="!validScopedScorecardTemplate" :loading="isSaving" @click='createScorecard();')
                        v-icon mdi-plus
                        | Create

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ScorecardTemplateCard from "@/components/scorecard_templates/card.vue";

    // Data
    import { GetAvailableScorecardTemplates, CreateScorecard, } from "@/data";

    export default Vue.extend({
        data() {
            return {
                availableScorecardTemplates: [],
                dialog: false,
                isLoading: false,
                isSaving: false,
                isShowMoreLoading: false,
                lastScorecardTemplateId: null,
                limit: 50,
                scopedScorecardTemplate: null,
                showMore: true,
            }
        },
        methods: {
            getAvailableScorecardTemplates() {

                const options = {
                    last_scorecard_template_id: this.lastScorecardTemplateId || 0,
                    limit: this.limit,
                    status: 'active',
                }

                if (this.availableScorecardTemplates?.length > 0) {
                    this.isShowMoreLoading = true;
                } else {
                    this.isLoading = true;
                }

                GetAvailableScorecardTemplates(options).then((response) => {
                    if (response?.Data?.available_scorecard_templates?.length > 0) {

                        // set show moreable to true if records are returned and length is equal to limit
                        if (response.Data.available_scorecard_templates.length === this.limit) {
                            this.showMore = true;
                        } else { // set show moreable to false if records are returned and length is less than limit, indicating there are no more records to get
                            this.showMore = false;
                        }

                        // Get last record and set Id
                        this.lastScorecardTemplateId = response.Data.available_scorecard_templates[response.Data.available_scorecard_templates.length - 1]?.scorecard_template_id;

                        if (this.availableScorecardTemplates?.length > 0) {
                            this.availableScorecardTemplates = this.availableScorecardTemplates.concat(response.Data.available_scorecard_templates);
                        } else {
                            this.availableScorecardTemplates = response.Data.available_scorecard_templates;
                        }
                    } else {

                        // set show moreable to false because 0 records returned
                        this.showMore = false;
                    }

                    this.isLoading = false;
                    this.isShowMoreLoading = false;
                });
            },
            init() {
                this.availableScorecardTemplates = [];
                this.lastScorecardTemplateId = null;
                this.scopedScorecardTemplate = null;
                this.showMore = true,
                this.getAvailableScorecardTemplates();
            },
            onSelectCard(scorecardTemplate) {
                this.scopedScorecardTemplate = scorecardTemplate;
            },
            createScorecard() {
                if (!this.validScopedScorecardTemplate || !(this.conferenceId > 0)) {
                    return;
                }

                const options = {
                    communication_type:    'call',
                    conference_id:         this.conferenceId || 0,
                    scorecard_template_id: this.scopedScorecardTemplate.scorecard_template_id,
                }

                this.isSaving = true;
                CreateScorecard(options).then((response) => {
                    if (response?.Data?.scorecard?.vid?.length > 0) {
                        this.showSnackbar("green", "Successfully created scorecard");
                        this.$emit('create')
                    } else {
                        this.showSnackbar("error", "Failed to create scorecard");
                    }

                    this.isSaving = false;
                    this.dialog = false;
                });
            },
        },
        computed: {
            validAvailableScorecardTemplates() {
                return this.availableScorecardTemplates?.length > 0;
            },
            validScopedScorecardTemplate() {
                return this.scopedScorecardTemplate?.scorecard_template_id > 0;
            },
        },
        props: {
            conferenceId: Number,
            hideCreateUpdateDestroyButtons: Boolean,
            showSnackbar: Function,
        },
        components: {
            ScorecardTemplateCard,
        },
    });
</script>

<style lang="scss" scoped>
</style>
