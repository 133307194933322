<template lang="pug">
    v-dialog(v-if="isValidTekionAppointment" v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')

            v-btn(:disabled="!detailsCancelButtonEnabled" color="error" small dark v-on="on" @click="selectDispositionAppointmentForCancel()")
                v-icon mdi-cancel
                | Cancel

        v-card(width="100%" height="475px")
            v-card-text.text-center
                v-container
                    span.text-center.text-h6 {{`Cancel appointment for ${this.currentCampaignCustomer.customer.full_name}`}}

                v-card.mt-4(min-height="1px")
                    TableSimple(
                        dark
                        :items="[tekionAppointment]"
                    )
                        template(v-slot:th-default)
                            th.white--text Status
                            th.white--text Date/Time
                        template(v-slot:tr-default="slotProps")
                            td.text-left.xs-width
                                | {{ slotProps.item.appointment_status }}
                            td.text-left.xs-width
                                | {{ tableDateTime }}
                v-divider
                v-container.mt-4
                    v-row
                        v-col(cols='12')
                            v-text-field(
                                label='*Cancellation reason'
                                v-model="cancelForm.cancelReason"
                                type='text'
                                clearable
                                required
                                :rules="[rules.required]"
                            )
                        v-col(cols='12')
                            v-checkbox(
                                v-model="cancelForm.doNotNotifyCustomer"
                                label="Do Not Notify Customer"
                            )



            v-card-actions.d-flex.justify-end
                    v-btn.ma-2(color="primary" small outlined ripple @click="unselectDispositionAppointment(); closeDialog()")
                        v-icon mdi-close
                        | Close
                    v-btn.ma-2(:loading="isCanceling" :disabled="!cancelButtonEnabled" color="error" small outlined ripple @click="cancelTekionAppointment()")
                        v-icon mdi-cancel
                        | Confirm Cancelation


</template>

<script lang="ts">
    import Vue from 'vue';
    //Component
    import TableSimple from '@/components/shared/tables/table_simple.vue'

    //Data
    import { CancelTekionAppointment } from "@/data";

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                isCanceling: false, 
                showCancelAppointmentConfirmation: false,
                cancelForm: {
                    cancelReason: null,
                    doNotNotifyCustomer: false,
                },
                rules: {
                    required: v => !!v || 'Required',
                },
            }
        },
        methods: {
            cancelTekionAppointment(){
                if (!(this.currentCampaignCustomer?.campaign_customer_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id || 0 ,
                    cancel_reason: this.cancelForm?.cancelReason || "",
                    do_not_notify_customer: this.cancelForm?.doNotNotifyCustomer || false,
                };

                this.isCanceling = true; 
                
                CancelTekionAppointment(options, this.tekionAppointment.tekion_appointment_id).then((response) => {
                    if (response?.Data?.ok === true) {
                        this.showSnackbar("success", "Successfully canceled Tekion Appointment");
                        this.displayDetailsView();
                        this.handleCancelTekionAppointment(this.tekionAppointment);
                        this.handleCloseDetailsOnCancel();
                        this.closeDialog();
                    } else {
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                            this.closeDialog();
                        }
                        let err = "Unable to update this Tekion Appointment";
                        if (response?.Error?.length > 0) {
                            err = response.Error;
                        }
                        this.showSnackbar("error", err);
                        
                    }
                    this.isCanceling = false; 
                });
            },
            closeDialog(){
                this.dialog = false;
            },
            selectDispositionAppointmentForCancel(){
                //Set only id in order to select the appointment in the dispositions tab for cancel
                const tekionAppointmentDetails = {
                    appointment_id: this.tekionAppointment?.appointment_id || 0
                }

                this.setTekionAppointmentDetails(tekionAppointmentDetails)
            },
            unselectDispositionAppointment(){
                //Set appointment id to 0 so watcher unselects disposition in customer_profile -> tekionAppointmentDetails
                const tekionAppointmentDetails = {
                    appointment_id: 0,
                }
                this.setTekionAppointmentDetails(tekionAppointmentDetails);
            }
        },
        computed: {
            isValidTekionAppointment(){
                return this.tekionAppointment?.tekion_appointment_id > 0;
            },
            cancelButtonEnabled(){
                return this.cancelForm?.cancelReason?.length > 0;
            },
            detailsCancelButtonEnabled(){
                return this.tekionAppointment?.appointment_status.toLowerCase() === "new";
            }, 
            tableDateTime(){
                return this.convertAppointmentDateTimeFromMilliseconds(parseInt(this.tekionAppointment.appointment_date_time));
            }
        },
        components: {
            TableSimple
        },
        props: {
            convertAppointmentDateTimeFromMilliseconds: Function, 
            currentCampaignCustomer: Object,
            displayDetailsView: Function,
            getTekionAppointments: Function,
            handleCancelTekionAppointment: Function,
            handleCloseDetailsOnCancel: Function, 
            setTekionAppointmentDetails: Function,
            setTekionError: Function,
            showSnackbar: Function,
            tekionAppointment: Object,
        }
    });
</script>

<style lang="scss" scoped>
.cancel-container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
