import {get, put, post, destroy} from "@/data/index";

export function CreateCommunicationQueueGroup(options = {}) {
    return post<any>("/app/api/communication_queue_groups", options);
}

export function CreateAllCommunicationQueueCommunicationQueueGroups(vid: string) {
	return post<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_communication_queue_groups");
}
export function CreateAllCommunicationQueueGroupUsers(vid: string) {
	return post<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_group_users");
}

export function GetAvailableCommunicationQueuesForCommunicationQueueGroup(vid: string, options = {}) {
	return get<any>("/app/api/communication_queue_groups/" + (vid) + "/available_communication_queues", options);
}

export function GetAvailableUsersForCommunicationQueueGroup(vid: string, options = {}) {
	return get<any>("/app/api/communication_queue_groups/" + (vid) + "/available_users", options);
}

export function DestroyCommunicationQueueGroup(vid: string, options = {}) {
	return destroy<any>("/app/api/communication_queue_groups/" + vid, options);
}

export function DestroyAllCommunicationQueueCommunicationQueueGroups(vid: string) {
	return destroy<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_communication_queue_groups");
}

export function DestroyAllCommunicationQueueGroupUsers(vid: string) {
	return destroy<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_group_users");
}

export function FindCommunicationQueueGroup(vid: string) {
    return get<any>("/app/api/communication_queue_groups/" + vid);
}

export function GetCommunicationQueueGroups(options = {}) {
    return get<any>("/app/api/communication_queue_groups", options)
}

export function UpdateCommunicationQueueGroup(vid: string, options = {}) {
	return put<any>("/app/api/communication_queue_groups/" + (vid || 0).toString(), options);
}