import { get, post, put } from '.';

export function CancelScheduledCommunication(id) {
	return put<any>("/app/api/scheduled_communications/" + (id || 0).toString() + "/cancel");
}

export function CreateScheduledCommunication(options = {}) {
	return post<any>("/app/api/scheduled_communications", options);
}

export function FindScheduledCommunication(id) {
	return get<any>("/app/api/scheduled_communications/" + (id || 0).toString());
}

export function GetScheduledCommunicationsCountForDeletion(options = {}) {
	return get<any>("/app/api/scheduled_communications/count", options);
}

export function GetScheduledCommunications(options = {}) {
	return get<any>("/app/api/scheduled_communications", options);
}
export function ArchiveScheduledCommunications(options = {}) {
	return put<any>("/app/api/archive_scheduled_communications", options)
}

export function DeleteScheduledCommunications(options = {}) {
	return put<any>("/app/api/archive_scheduled_communications/mass_delete", options)
}

export function AssignNextSavedPlaceInLine(options = {}) {
	return put<any>("/app/api/scheduled_communications/next", options)
}

export function AcceptSavedPlaceCall(id: string, options = {}) {
	return post <any>("/app/api/scheduled_communications/" + (id || 0).toString() + "/accept", options = {});
}

export function DeclineSavedPlaceCall(id: string, options = {}) {
	return post <any>("/app/api/scheduled_communications/" + (id || 0).toString() + "/decline", options = {});
}