import { get, post, destroy, put } from '.';

export function GetIvrTrees(options = {}) {
	return get<any>("/app/api/ivr_trees", options);
}

export function FindIvrTree(id: string, options = {}) {
	return get<any>(`/app/api/ivr_trees/${id}`, options);
}

export function CreateIvrTree(options = {}) {
	return post<any>("/app/api/ivr_trees", options);
}

export function UpdateIvrTree(id: string, options = {}) {
	return put<any>(`/app/api/ivr_trees/${id}`, options);
}

export function DestroyIvrTree(id: string, options = {}) {
	return destroy<any>(`/app/api/ivr_trees/${id}`, options);
}

export function GetIvrTreeMp3s(id: string, options = {}) {
	return get<any>(`/app/api/ivr_trees/${id}/mp3s`, options);
}

export function CopyIvrTree(options = {}) {
	return post<any>("/app/api/ivr_trees/copy", options);
}

export function TestCallIvrTree(id: string, options = {}) {
	return post<any>(`/app/api/ivr_trees/${id}/test_call`, options)
}
