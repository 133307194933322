export function isShowMore(responseDataLength: number, limit: number) {
    if (!(limit > 0)) {
        return false;
    }

    if (responseDataLength === limit) {
        return true;
    }

    return false;
}
