<template lang="pug">
    v-card.pa-4
        v-row
            v-col(cols="12")
                h3(class="subtitle-2") Alternate Delivery Contact
            v-col(cols="6")
                v-text-field(
                    v-model="deliveryContact.firstName"
                    label="First Name *"
                    :rules="[rules.required]"
                    clearable
                    validate-on-blur
                    @input="emitUpdate"
                )
            v-col(cols="6")
                v-text-field(
                    v-model="deliveryContact.lastName"
                    label="Last Name *"
                    :rules="[rules.required]"
                    clearable
                    validate-on-blur
                    @input="emitUpdate"
                )
        v-row
            v-col(cols="12")
                v-text-field(
                    v-model="deliveryContact.email"
                    label="Email *"
                    :rules="[rules.required, rules.email]"
                    clearable
                    validate-on-blur
                    @input="emitUpdate"
                )
        v-row
            v-col(cols="6")
                v-text-field(
                    v-model="deliveryContact.phones[0].number"
                    label="Phone Number *"
                    :rules="[rules.required]"
                    clearable
                    validate-on-blur
                    @input="emitUpdate"
                )
            v-col(cols="6")
                v-select(
                    v-model="deliveryContact.phones[0].phoneType"
                    :items="phone_types"
                    label="Phone Type *"
                    :rules="[rules.required]"
                    @change="emitUpdate"
                )
        v-row
            v-col(cols="12")
                v-select(
                    v-model="deliveryContact.customerType"
                    :items="customer_types"
                    label="Customer Type *"
                    :rules="[rules.required]"
                    @change="emitUpdate"
                )
        v-row
            v-col(v-if="deliveryContact.customerType === 'BUSINESS'" cols="12")
                v-text-field(
                    v-model="deliveryContact.companyName"
                    label="Company Name *"
                    :rules="[rules.required]"
                    clearable
                    validate-on-blur
                    @input="emitUpdate"
                )
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                customer_types: [
                    { text: 'Individual', value: 'INDIVIDUAL' },
                    { text: 'Business', value: 'BUSINESS' },
                ],
                deliveryContact: {
                    id: null,
                    companyName: null,
                    customerType: null,
                    email: null,
                    firstName: null,
                    lastName: null,
                    phones: [{
                        number: null,
                        phoneType: null,
                    }]
                },
                phone_types: [
                    { text: 'Home', value: 'HOME' },
                    { text: 'Mobile', value: 'MOBILE' },
                    { text: 'Work', value: 'WORK' }
                ],
                rules: {
                    required: (v: any) => !!v || 'Required.',
                    email: v => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(v) || 'Invalid e-mail.'
                    }
                }
            }
        },
        methods: {
            resetDeliveryContact() {
                this.deliveryContact = {
                    id: null,
                    company_name: null,
                    customer_type: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    phones: [{
                        phone_number: null,
                        phone_type: null,
                    }]
                };
                this.emitUpdate();
            },
            emitUpdate() {
                this.$emit('input', this.deliveryContact);
            }
        },
        props: {
            
            value: {
                type: Object,
                default: () => ({})
            }
        },
        watch: {
            value: {
                handler(newValue) {
                    if (newValue && Object.keys(newValue).length > 0) {
                        this.deliveryContact = {...newValue};
                    }
                },
                immediate: true,
                deep: true
            },
            'deliveryContact.customer_type': {
                handler(newValue: string) {
                    if (newValue === 'INDIVIDUAL') {
                        this.deliveryContact.company_name = null;
                    }
                }
            },
            sameAsCustomer: {
                handler(newValue: boolean) {
                    if (newValue) {
                        this.resetDeliveryContact();
                    }
                }
            },
        },
    });
</script>
