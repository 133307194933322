<template lang="pug">
    div(style="min-height: 400px;")
        v-row.d-flex.justify-space-between
                v-col.d-flex.justify-start
                    v-btn.mb-4(color="primary" small outlined ripple @click="displayDetailsView()")
                        v-icon mdi-arrow-left
                        | Appointments
        .text-subtitle-2.subtitle-2-padding.text-center
            h5 Find Available Appointments {{ isLoading ? 'Loading...' : '' }}
                div.mt-4#picker-wrapper
                    .reset-cover
                        .reset-details
                            .text-center
                                p.text-small.mb-0 Dates:
                                p.text-small {{ dateRange[0] }} - {{ dateRange[1] }}
                            .text-center(v-if="appointmentSlots.shopId")
                                p.text-small.mb-0 Shop:
                                p.text-small {{ shops.find(shop => shop.sid === appointmentSlots.shopId)?.name }}
                            .text-center(v-if="appointmentSlots.transportationId")
                                p.text-small.mb-0 Transportation:
                                p.text-small {{ transportationOptions.find(transportation => transportation.sid === appointmentSlots.transportationId)?.name }}
                            .text-center(v-if="appointmentSlots.serviceAdvisorId")
                                p.text-small.mb-0 Service Advisor:
                                p.text-small {{ employees.find(employee => employee.dms_key === appointmentSlots.serviceAdvisorId)?.display_name }}
                            v-btn(
                                color='white'
                                @click='resetForm'
                                outlined
                            )
                                | Retry search
                    div#date-selector

                        v-row(justify="center")
                            v-col(cols='9')
                                v-date-picker(
                                    v-model='dateRange'
                                    full-width
                                    label='Select Date Range'
                                    range
                                    required
                                    elevation="5"
                                    :min='minimumDate'
                                    :rules="[rules.required]"
                                )
                            v-col(cols='6')
                                v-select(
                                    v-model="appointmentSlots.shopId"
                                            :items="[{ name: 'Select Shop', sid: '' }, ...shops]"
                                            item-text="name"
                                            item-value="sid"
                                            label="Shop"
                                            outlined
                                            dense
                                            required
                                            :rules="[rules.required]"
                                            placeholder="Select Shop"
                                )
                            v-col(cols='6')
                                v-select(
                                    v-model="appointmentSlots.transportationId"
                                    :items="[{ name: 'Select Transportation Type', sid: '' }, ...transportationOptions]"
                                    item-text="name"
                                    item-value="sid"
                                    label="Transportation"
                                    outlined
                                    dense
                                    required
                                    :rules="[rules.required]"
                                    placeholder="Select Transportation Type"
                                )
                            v-col(cols='9')
                                v-select(
                                    v-model='appointmentSlots.serviceAdvisorId'
                                    :items="validEmployees"
                                    item-text='display_name_role'
                                    item-value='tekion_employee_sid'
                                    label='Service Advisor'
                                    outlined
                                    dense
                                    required
                                    :rules="[rules.required]"
                                )

                            v-col(cols='9')
                                v-btn(
                                    color='primary'
                                    :disabled='disabledFind'
                                    block
                                    @click='getAvailableSlots'
                                )
                                    | Find Time Slots
                    div#slot-picker
                        div(v-if="!isLoading && !showNotFound" class="slots-list scrollable-panel")
                            div(v-for="(day, dayIndex) in availableDaysWithSlots" :key="dayIndex")
                                v-subheader.d-flex.justify-center.font-weight-bold {{ formatSubheaderDate(day.appointmentDate) }}
                                template(v-if="day.slots && day.slots.length > 0")
                                    div.slot-item(v-for="(slot, slotIndex) in day.slots" :key="slotIndex" @click="selectSlot(slot)" :class="{'unavailable': !slot.isAvailable}")
                                        p Time:
                                        p {{ convertAppointmentDateTimeFromMilliseconds(slot.startTime) }}
                                template(v-else)
                                    div.slot-item.unavailable
                                        p No slots available

                        div(v-if="!isLoading && showNotFound")
                            v-alert(outlined type='warning' dense border='left')
                                | No available slots found please adjust your search criteria

                        div(v-if="isLoading" class="text-center")
                            v-progress-circular(
                                :size='100'
                                :width='10'
                                color='#0f5b9a'
                                indeterminate
                                style="margin-top: 100px;"
                            )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Data
    import { GetTekionAppointmentSlots, GetTekionTransportationTypes, GetTekionEmployees, GetTekionServiceShops, GetCustomerVehicles } from "@/data";

    // Components

    //Helpers
    import { isShowMore } from '@/helpers/show_more';



    export default Vue.extend({
        data() {
            return {
                appointmentSlots: {
                    shopId: '',
                    transportationId: '',
                    serviceAdvisorId: '',
                },
                availableDaysWithSlots: [],
                // customerVehicles: [],
                dateRange: [],
                employees: [],
                employeeOffset: 0,
                employeeLimit: 100,
                expandSlots: false,
                hasSearched: false,
                isLoading: false,
                minimumDate: "2016-06-15",
                rules: {
                    required: v => !!v || 'Required.',
                },
                selectedSlot: null,
                serviceAdvisorOptions: [],
                shops: [],
                showMoreEmployees: false,
                transportationOptions: [],
            }
        },
        methods: {
            convertAppointmentDateTime(epochTime) {
                const date = new Date(epochTime);
                return `${date.toLocaleDateString("en-US")}`;
            },
            formatSlotDateTime(timestamp) {
                const date = new Date(timestamp);
                return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
            },
            formatSubheaderDate(timestamp) {
                const date = new Date(timestamp);
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                const dayName = days[date.getDay()];
                const monthName = months[date.getMonth()];
                const day = date.getDate();

                // Get ordinal suffix (1st, 2nd, 3rd, etc.)
                const suffix = (() => {
                    if (day > 3 && day < 21) return 'th';
                    switch (day % 10) {
                        case 1:  return "st";
                        case 2:  return "nd";
                        case 3:  return "rd";
                        default: return "th";
                    }
                })();

                return `${dayName} ${monthName} ${day}${suffix}`;
            },
            getShops(){
                const organizationId = this.currentCampaignCustomer.organization_id;

                const options = {
                    organization_id: organizationId,
                };
                this.isLoading = true;

                GetTekionServiceShops(options).then((response) => {
                    if(response?.Data?.tekion_service_shops?.length > 0) {
                        this.shops = response.Data.tekion_service_shops
                    } else {
                        this.shops = [];
                        this.showSnackbar("red", "No service shops available.");
                    }
                    this.isLoading = false;
                })
            },
            handleShowMoreEmployees(){
                this.employeeOffset += this.employeeLimit
                this.getEmployees();
            },
            init() {
                this.initMinDate();
                this.getEmployees();
                this.getShops();
                this.getTransportationTypes();
            },
            initMinDate(){
                // set minimum date to today with format 2016-06-15
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yyyy = today.getFullYear();
                this.minimumDate = `${yyyy}-${mm}-${dd}`;
            },

            getAvailableSlots() {
                if (!this.dateRangeValidation.isValid) {
                    this.showSnackbar("red", this.dateRangeValidation.message);
                    return;
                }

                const { startDate, endDate } = this.dateRangeValidation.dates;

                const options = {
                    organization_id: this.currentCampaignCustomer.organization_id,
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    shop_sid: this.appointmentSlots.shopId,
                    transportation_sid:  this.appointmentSlots.transportationId,
                    service_advisor_sid: this.appointmentSlots.transportationId,
                    start_date: startDate,
                    end_date: endDate,
                };

                this.isLoading = true;
                this.hasSearched = true;

                GetTekionAppointmentSlots(options).then((response) => {
                    if(response?.Data?.appointment_days?.length > 0) {
                        this.availableDaysWithSlots = this.sortAppointmentDays(response.Data.appointment_days);
                        this.isLoading = false;
                        this.showSnackbar("green", "Choose from available slots");
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                        }
                    } else {
                        this.availableDaysWithSlots = [];
                        this.isLoading = false;
                        this.showSnackbar("red", "No available slots found please adjust your search criteria");
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                        }
                    }
                })

                 // add class to picker-wrapper of found-slots
                const pickerWrapper = document.getElementById('picker-wrapper');
                if (pickerWrapper) {
                    pickerWrapper.classList.add('slots-available');
                }
            },
            getEmployees() {
                if (this.currentCampaignCustomer?.campaign_customer_id === 0) {
                    return
                }

                const options = {
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    is_dms: true,
                    job_title_name: "service",
                    limit: this.employeeLimit,
                    offset: this.employeeOffset
                }

                //May need to revisit - could be tekion_employees instead of users/employees from volie
                GetTekionEmployees(options).then((response) => {
                    if (response?.Data?.employees?.length > 0) {
                        if (this.employees?.length > 0 ) {
                         this.employees = this.employees.concat(response.Data.employees)
                        } else {
                            this.employees = response.Data.employees;
                        }

                        if (response?.Data?.employees?.length == this.employeeLimit) {
                            this.employeeOffset += this.employeeLimit
                            this.getEmployees();
                        }

                    }
                    this.showMoreEmployees = isShowMore(response?.Data?.employees?.length, this.employeeLimit)
                });
            },
            getTransportationTypes() {
                if (!(this.currentCampaignCustomer.campaign_customer_id > 0)) {
                    return;
                }

                const options = {
                    organization_id: this.currentCampaignCustomer.organization_id,
                }

                GetTekionTransportationTypes(options).then((response) => {
                    if (response?.Data?.tekion_transportation_types?.length > 0) {
                        this.transportationOptions = response.Data.tekion_transportation_types;
                    }
                    if(this.transportationOptions.length > 0) {
                        this.transportationOptions = this.transportationOptions.map((option) => {
                            return {
                                ...option,
                                name: option.name.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
                            }
                        });

                    }
                });


            },
            resetForm() {
                // remove class from picker-wrapper

                const pickerWrapper = document.getElementById('picker-wrapper');
                if (pickerWrapper) {
                    pickerWrapper.classList.remove('slots-available');
                }
                this.availableDaysWithSlots = [];
                this.dateRange = [];
                this.hasSearched = false;
            },
            selectSlot(slot) {
                if (!slot.isAvailable) {
                    this.showSnackbar("red", "Slot is not available");
                    return;
                }
                const formObject = {
                    tekion_shop_sid: this.appointmentSlots.shopId,
                    tekion_service_advisor_sid: this.appointmentSlots.serviceAdvisorId,
                    tekion_transportation_type_sid: this.appointmentSlots.transportationId,
                }

                this.selectedSlot = slot;
                //set slot scoped slot, slotfor object, and navigate back to either create or udpate form
                this.handleSlotSelected(slot, formObject, this.isEdit);
            },
            sortAppointmentDays(appointmentDays) {
                if (!Array.isArray(appointmentDays)) return [];

                return appointmentDays.sort((a, b) => a.appointmentDate - b.appointmentDate);
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            showNotFound() {
                return this.hasSearched && this.availableDaysWithSlots.length === 0;
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_id > 0;
            },
            disabledFind() {
                return !this.validCampaignCustomer || !this.appointmentSlots.shopId || !this.appointmentSlots.transportationId || !this.appointmentSlots.serviceAdvisorId;
            },
            isEdit() {
                return this.scopedTekionAppointment?.tekion_appointment_id > 0;
            },
            validEmployees() {
                return this.employees.filter(employee => {
                    return employee.display_name_role &&
                        employee.display_name_role.trim() !== '' &&
                        employee.tekion_employee_sid &&
                        employee.tekion_employee_sid.trim() !== '';
                });
            },
            dateRangeValidation() {
                if (!this.dateRange?.length) {
                    return {
                        isValid: false,
                        message: "Please select a date range",
                        dates: null
                    };
                }

                let [startDate, endDate] = this.dateRange;

                // Swap dates if start is after end
                if (new Date(startDate) > new Date(endDate)) {
                    [startDate, endDate] = [endDate, startDate];
                }

                const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
                if (new Date(endDate).getTime() - new Date(startDate).getTime() > oneWeekInMilliseconds) {
                    return {
                        isValid: false,
                        message: "Date range cannot be more than 7 days",
                        dates: null
                    };
                }

                return {
                    isValid: true,
                    message: "Valid date range",
                    dates: { startDate, endDate }
                };
            },
        },
        watch: {
            dateRange: {
                handler(newRange) {
                    if (!newRange?.length) return;

                    let [startDate, endDate] = newRange;
                     // Swap dates if start is after end
                    if (new Date(startDate) > new Date(endDate)) {
                        [startDate, endDate] = [endDate, startDate];
                    }
                    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
                    const dateDiff = new Date(endDate).getTime() - new Date(startDate).getTime();

                    if (dateDiff > oneWeekInMilliseconds) {
                        this.showSnackbar("red", "Date range cannot be more than 7 days");
                    }
                },
                deep: true
            }
        },
        components: {
        },
        props: {
            convertAppointmentDateTimeFromMilliseconds: Function,
            currentCampaignCustomer: Object,
            displayCreateView: Function,
            displayDetailsView: Function,
            displayRescheduleView: Function,
            handleSlotSelected: Function,
            isTekionEnabled: Boolean,
            setTekionError: Function,
            scopedTekionAppointment: Object,
            showSnackbar: Function,
        },
        created() {
            this.init();
        },
        destroyed() {
        },
    })
</script>

<style lang="scss" scoped>
    #app {
        #picker-wrapper {
            width: 100%;
            // if slots available then scale picker-wrapper to 50%
            #date-selector {
                transition: transform 0.5s ease-in-out;
            }
            .reset-cover {
                display: none;
                transition: background-color 0.5s ease-in-out;
            }
            &.slots-available {
                display: flex;
                #date-selector {
                    flex-basis: 33.33%;
                    transform: scale(0.5);
                    transform-origin: top left;
                    position: relative;
                    > div.row {
                        position: absolute;
                        top: 20%;
                        width: 230%;
                    }
                }
                #slot-picker {
                    flex-basis: 55.66%;
                    padding: 20px;
                    margin-left: 10%;
                    max-height: 400px;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
                .reset-cover {
                    position: absolute;
                    top: 10%;
                    bottom: 0;
                    height: 100%;
                    background-color: rgba(15, 15, 15, 0);
                    z-index: 10;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    padding: 10px;
                    margin-left: -5px;
                    max-width: 40%;
                    .reset-details {
                        opacity: 0;
                        transition: opacity 0.5s ease-in-out;
                        color: #ffffff;
                    }
                }
                .reset-cover:hover {
                    background-color: rgba(15, 15, 15, 0.9);
                    .reset-details {
                        opacity: 1;
                    }
                }
            }
            .slot-item {
                padding: 10px;
                border: 2px solid #010101;
                margin-bottom: 10px;
                cursor: pointer;
                background-color: #eaf6ea;
                transition: background-color 0.5s ease-in-out;
                &:hover {
                    background-color: #70cf70;
                }
                p {
                    margin: 0;
                    font-size: 0.8rem;
                }
                &.unavailable {
                    background-color: #fb8282;
                    &:hover {
                        background-color: #f95858;
                    }
                    cursor: not-allowed;
                }
            }
        }
    }
</style>
