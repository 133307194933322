<template lang="pug">
    v-card.width-100-percent(flat)
        v-card-text.pa-0
            ScorecardNewDialog(
                :conference-id="conferenceId"
                :show-snackbar="showSnackbar"
                @create="getScorecards"         
            )

            TableFixedHeader(
                :height="250"
                hide-count
                :is-loading="isLoading"
                :items="scorecards"
                :num-of-sticky-columns="0"
            )
                template(v-slot:th-default) 
                    th.text-left.no-wrap Template
                    th.text-left.no-wrap Score
                    th.text-left.no-wrap Status

                template(v-slot:tr-default="slotProps")
                    td.no-wrap.xs-width
                        router-link(:to="{ name: 'scorecards_show', params: { vid: slotProps.item.vid } }" target="_blank" style="text-decoration: none;")
                            StrLimitTooltipText(:text="slotProps.item.name" :char-limit="30")
                    td.no-wrap.xs-xs-width
                        template(v-if="slotProps.item.status === 'pending'")
                            | ---
                        template(v-else)
                            strong(:class="scoreColorClass(scorePercentage(slotProps.item))")
                                | {{ scorePercentage(slotProps.item).toFixed(2) }}%
                    td.no-wrap.xs-xs-width
                        StatusText(:text="slotProps.item.status")

                template(v-if="!validScorecards && !isLoading" v-slot:table-alert)
                    v-alert(outlined type='info' dense border='left' style="margin: 10px 0px;")
                        | Unable to find any scorecards.

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ScorecardNewDialog from "@/components/scorecards/new_dialog.vue";
    import StatusText from "@/components/shared/status_text.vue";
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";
    import TableFixedHeader from "@/components/shared/table_fixed_header.vue";

    // Data
    import { 
        GetScorecards,
    } from '@/data';

    // Helpers
    import { getScorecardScorePercentage, scorecardScoreColorClass, } from "@/helpers/scorecard";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
                scorecards: [],
            }
        },
        methods: {
            getScorecards() {
                const options = {
                    conference_id: this.conferenceId || 0,
                    status:         'all',
                }
                
                this.isLoading = true;
                GetScorecards(options).then((response) => {
                    if (response?.Data?.scorecards?.length > 0) { 
                        this.scorecards = response.Data.scorecards;
                    }

                    this.isLoading = false;
                });
            },
            init() {
                this.getScorecards();
            },
            scoreColorClass(score) {
                return scorecardScoreColorClass(score, 100);
            },
            scorePercentage(scorecard) {
                if (!(scorecard.points_available > 0)) {
                    return 0;
                }

                return getScorecardScorePercentage(scorecard.points_scored, scorecard.points_available);
            },
        },
        computed: {
            validScorecards() {
                return this.scorecards?.length > 0;
            }
        },
        props: {
            conferenceId: Number,
            showSnackbar: Function,
        },
        components: {
            ScorecardNewDialog,
            TableFixedHeader,
            StatusText,
            StrLimitTooltipText,
        },
        created() {
            this.init();
        },
    });
</script>

<style lang="scss" scoped>
</style>