<template lang="pug">
    v-card(
        v-if="scorecardTemplate"
        tile
        hover
        :disabled="isOpen"
        @click="onClickCard()"
        :style="isOpen ? 'background-color: #f5f5f5;' : ''"
    )
        v-list.pb-0(subheader two-line)
            v-list-item.pr-0
                //- v-list-item-avatar

                v-list-item-content.py-5
                    v-list-item-title(style="font-weight: 500;")
                        | {{ scorecardTemplate.name }}

                    v-list-item-subtitle
                        | {{ scorecardTemplate.description }}
                
                v-list-item-action.py-5.my-0
                    v-list-item-title
                        StatusText(:text="scorecardTemplate.status")

                    v-list-item-subtitle Status

                //- v-list-item-action.py-5.my-0
                //-     v-list-item-title

                    v-list-item-subtitle

                v-divider.ml-4(vertical :style="isOpen ? 'min-width: 10px; max-width: 10px; background-color: #0f5b9a;': 'min-width: 10px; max-width: 10px; background-color: #f5f5f5;'")

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import StatusText from '@/components/shared/status_text.vue';

    export default Vue.extend({
        methods: {
            onClickCard() {
                this.selectCard(this.scorecardTemplate);
            },
        },
        computed: {
            isOpen() {
                if (!this.validScorecardTemplate || !this.validScopedScorecardTemplate) {
                    return false
                }

                if (!(this.scorecardTemplate.scorecard_template_id === this.scopedScorecardTemplate.scorecard_template_id)) {
                    return false;
                }

                return true;
            },
            validScopedScorecardTemplate() {
                return this.scopedScorecardTemplate?.scorecard_template_id > 0;
            },
            validScorecardTemplate() {
                return this.scorecardTemplate?.scorecard_template_id > 0;
            },
        },
        components: {
            StatusText,
        },
        props: {
            scopedScorecardTemplate: Object,
            scorecardTemplate: Object,
            selectCard: Function,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        
     }
</style>
