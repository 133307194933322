import { render, staticRenderFns } from "./new_dialog.vue?vue&type=template&id=9be8729e&scoped=true&lang=pug"
import script from "./new_dialog.vue?vue&type=script&lang=ts"
export * from "./new_dialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9be8729e",
  null
  
)

export default component.exports