import { post, get, destroy } from '.';

export function GetCommunicationQueueCommunicationQueueGroups(vid: string, options = {}) {
	return get<any>("/app/api/communication_queue_groups/" + (vid) + "/communication_queue_communication_queue_groups", options);
}

export function CreateCommunicationQueueCommunicationQueueGroup(options = {}) {
	return post<any>("/app/api/communication_queue_communication_queue_groups", options);
}

export function DestroyCommunicationQueueCommunicationQueueGroup(vid: string) {
	return destroy<any>("/app/api/communication_queue_communication_queue_groups/" + (vid));
}