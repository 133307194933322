import { get, put, } from '.';

export function GetScorecardItems(scorecardId, options = {}) {
	return get<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/scorecard_items", options);
}

export function UpdateScorecardItemAnswer(scorecardId, scorecardItemId, options = {}) {
	return put<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/scorecard_items/" + (scorecardItemId || 0).toString() + "/answer", options);
}

export function UpdateScorecardItemNotes(scorecardId, scorecardItemId, options = {}) {
	return put<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/scorecard_items/" + (scorecardItemId || 0).toString() + "/notes", options);
}
