<template lang="pug">
    v-form(style="margin-bottom: -40px;")
        .d-flex.align-center.justify-center.mt-4
            v-btn.mr-2(
                color="primary"
                outlined
                ripple
                @click="displaySlotsView()"
            )
                v-icon(left) mdi-calendar-search
                | Find New Slot
        v-banner(
            class="mb-10 mt-4"
            color="white"
            elevation="1"
            outlined
            rounded
        )
            .d-flex.align-center.justify-center
                v-icon.mr-2(color="primary") mdi-calendar
                span.font-weight-bold.primary--text Appointment:
                span.ml-2(v-if="validSelectedAppointmentDateTime") {{ displayDateTime }}
                span.ml-2(v-else) ---

        v-row(justify="end")
            v-btn(
                @click="showVehicleForm = !showVehicleForm"
                class="mb-2 mr-4"
                color="success"
                outlined
                small
            )
                | New Vehicle
                v-icon(color="success") {{ showVehicleForm ? 'mdi-chevron-up' : 'mdi-chevron-down' }}

            v-expand-transition
                div(v-if="showVehicleForm" class="pa-4")
                    v-row

                        v-col(cols="4")
                            v-text-field(
                                v-model="formAppointment.vehicle_year"
                                label='Year *'
                                type='text'
                                clearable
                                :rules="[rules.required]"
                                validate-on-blur
                            )

                        v-col(cols="4")
                            v-text-field(
                                v-model="formAppointment.vehicle_make"
                                label='Make *'
                                type='text'
                                clearable
                                :rules="[rules.required]"
                                validate-on-blur
                            )

                        v-col(cols="4")
                            v-text-field(
                                v-model="formAppointment.vehicle_model"
                                label='Model *'
                                type='text'
                                clearable
                                :rules="[rules.required]"
                                validate-on-blur
                            )
                    v-row
                        v-col(cols="12")
                            v-text-field(
                                v-if="!isEdit"
                                v-model="vehicleVin"
                                label='Vin (Required for Service Menu Job Type)'
                                type='text'
                                clearable
                                counter="17"
                                :rules="[rules.vinRule]"
                                validate-on-blur
                            )

        v-autocomplete(
            v-if="!showVehicleForm"
            v-model="formAppointment.vehicle_id"
            :items="customerVehicles"
            :item-text="vehicleLabel"
            item-value="vehicle_id"
            label="Vehicle *"
            required
            clearable
        )

        v-checkbox(
            v-if="showJobsForm && !isEdit"
            v-model="formAppointment.is_default_job"
            label="Use Organization default job type"
            :disabled="!hasDefaultOpCodes"
        )

        v-autocomplete(
            v-if="showJobsForm && !formAppointment.is_default_job && !isEdit"
            v-model="formAppointment.job_type"
            :items="jobs"
            label="Job Type *"
            item-text="key"
            item-value="value"
            required
            clearable
        )
        div.text-center(v-if="opcodesLoading && !isEdit")
            v-progress-circular(
                :size='65'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 75px; margin-bottom: 75px;"
            )
        v-autocomplete(
            v-if="showOpcodes && !isEdit"
            v-model="formAppointment.tekion_opcodes"
            :items="tekionOpcodes"
            label="Op Code(s) *"
            item-text="description"
            item-value="tekion_opcode_id"
            :item-text-length="30"
            required
            :rules="[rules.required]"
            multiple
            clearable
            chips
            deletable-chips
        )
            template(v-slot:item="{ item }")
                StrLimitTooltipText(:text="item.description" :char-limit="40")
        div.text-center(v-if="serviceMenuLoading && !isEdit")
            v-progress-circular(
                :size='65'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 75px; margin-bottom: 75px;"
            )
        v-autocomplete(
            v-if="showServiceMenuForm && !isEdit"
            v-model="formAppointment.tekion_service_menu_id"
            :items="tekionServiceMenus"
            label="Service Menu *"
            item-text="Description"
            item-value="Id"
            required
            :rules="[rules.required]"
        )
        v-divider.pb-4
        v-textarea(
            v-model="formAppointment.customer_comments"
            label='Customer Comments'
            rows="3"
            auto-grow
            clearable
            outlined
            dense
        )
        v-checkbox(
            v-model="formAppointment.delivery_contact_same_as_customer "
            label="Delivery contact same as customer"
            required
        )

        DeliveryContact(
            v-if="showDeliveryContact"
            v-model="formAppointment.delivery_contact"
        )

        v-checkbox(
            v-model="formAppointment.notify_customer"
            label="Notify customer"
            required
        )
        v-row.d-flex.justify-space-between
            v-col.d-flex.justify-start
                v-btn(color="primary" small outlined ripple @click="displayDetailsView()")
                    v-icon mdi-arrow-left
                    | Appointments
            v-col.d-flex.justify-end
                v-btn(v-if="isEdit" color="warning" small outlined ripple @click=" setDispositionDetails(); updateTekionAppointment(formAppointment)")
                    v-icon mdi-check
                    | Confirm Changes
                v-btn(v-else :disabled="!eligibleToSave" color="success" small outlined ripple @click='createTekionAppointment(formAppointment); setDispositionDetails()')
                    v-icon mdi-content-save
                    | Create



</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import DeliveryContact from '@/components/communication_center/shared/tabs/tekion/delivery_contact.vue';
    import StrLimitTooltipText from '@/components/shared/str_limit_tooltip_text.vue'

    // Data
    import { GetTekionOpcodes, GetTekionServiceMenus } from "@/data";

    export default Vue.extend({
        data() {
            return {
                formAppointment: {
                    appointment_date_time: null,
                    customer: null,
                    customer_comments: null,
                    delivery_contact: {
                        id: null,
                        companyName: null,
                        customerType: null,
                        email: null,
                        firstName: null,
                        lastName: null,
                        phones: [{
                            phone_number: null,
                            phone_type: null,
                        }]
                    },
                    delivery_contact_same_as_customer: true,
                    is_default_job: false,
                    job_type: null,
                    notify_customer: false,
                    organization_id: null,
                    post_tax_total_amount: {},
                    service_advisor_id: null,
                    shop_id: null,
                    tekion_opcodes: [],
                    tekion_service_menu_id: null,
                    transportation_type_id: null,
                    vehicle_id: null,
                    vehicle_make: null,
                    vehicle_model: null,
                    vehicle_year: null,
                },
                jobs: [
                    {key: "Default", value: "DEFAULT"},
                    // {key: "Recall", value: "RECALL"},
                    // {key: "Service Menu", value: "SERVICE_MENU"},
                ],
                opcodesLoading: false,
                organizationId: this.currentCampaignCustomer.organization_id,
                rules: {
                    vinRule: (v) => !v || v?.length === 0 || v.length === 17 || "Invalid vin, must be 17 character long",
                    required: (v) => !!v || v > 0 || "Required.",
                },
                showVehicleForm: false,
                serviceMenuLoading: false,
                tekionOpcodes: [],
                tekionOpcodesLimit: 500,
                tekionOpcodesShowMore: false,
                tekionServiceMenus: [],
                vehicleVin: null,
            }
        },
        methods: {
            convertToDateString(milliseconds: number): string {
                let timeZone = "America/New_York" // default to east coast

                if (this.currentCampaignCustomer?.organization_time_zone_location?.length > 0) {
                    timeZone = this.currentCampaignCustomer.organization_time_zone_location;
                }

                const date = new Date(milliseconds);
                return date.toLocaleDateString('en-CA', { timeZone }); // en-CA gives YYYY-MM-DD format
            },
            convertToTimeString(milliseconds: number): string {
                let timeZone = "America/New_York" // default to east coast

                if (this.currentCampaignCustomer?.organization_time_zone_location?.length > 0) {
                    timeZone = this.currentCampaignCustomer.organization_time_zone_location;
                }

                const date = new Date(milliseconds);
                const options: Intl.DateTimeFormatOptions = {
                    timeZone,
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false  // This gives us 24-hour format
                };

                const timeFormatter = new Intl.DateTimeFormat('en-US', options);
                return timeFormatter.format(date);
            },
            getTekionOpcodes() {
                if (!this.validOrganizationId) {
                    return;
                }

                const options = {
                    job_type: this.formAppointment.job_type,
                    limit: this.tekionOpcodesLimit,
                    offset: this.tekionOpcodes.length || 0,
                    organization_id: this.organizationId || 0
                }

                this.opcodesLoading = true;

                GetTekionOpcodes(options).then((response) => {
                    if (response.Data?.tekion_opcodes?.length > 0) {
                        if (response.Data?.tekion_opcodes?.length === this.limit) {
                            this.tekionOpcodesShowMore = true;
                        } else {
                            this.tekionOpcodesShowMore = false;
                        }

                        if (this.validTekionOpcodes) {
                            this.tekionOpcodes = this.tekionOpcode.concat(response.Data.tekion_opcodes);
                        } else {
                            this.tekionOpcodes = response.Data.tekion_opcodes;
                        }
                    } else {
                        this.tekionOpcodesShowMore = false;
                        this.formAppointment.job_type = null;
                        this.showSnackbar("error", "Unable to find opcodes for selected job type, please choose a different job type.")
                    }
                    this.opcodesLoading = false;
                });
            },
            getTekionServiceMenus() {
                if (!this.validOrganizationId) {
                    return;
                }

                const options = {
                    organization_id: this.organizationId || 0,
                    vin: this.vehicleVin,
                }

                this.serviceMenuLoading = true;

                GetTekionServiceMenus(options).then((response) => {
                    if (response.Data?.tekion_service_menus?.length > 0) {
                        this.tekionServiceMenus = response.Data.tekion_service_menus;
                    } else {
                        this.tekionServiceMenus = [];
                        this.showSnackbar("error", "Unable to find service menus for organization, please choose a different vehicle or job type.")
                        this.formAppointment.job_type = null;
                        this.jobs.splice(2, 1); // remove the service menu option (will get popped on if they change vehicles to a different valid vin)
                    }
                    this.serviceMenuLoading = false;
                });
            },
            initAppointment() {
                // init form for create
                if (!this.isEdit) {
                        this.formAppointment = {
                            appointment_date_time: this.scopedSlot.startTime,
                            customer: this.currentCampaignCustomer,
                            customer_comments: null,
                            delivery_contact: {
                                companyName: null,
                                customerType: null,
                                email: null,
                                firstName: null,
                                lastName: null,
                                phones: [{
                                    number: null,
                                    phoneType: null,
                                }]
                            },
                            delivery_contact_same_as_customer: true,
                            is_default_job: false,
                            job_type: null,
                            notify_customer: false,
                            organization_id: this.currentCampaignCustomer.organization_id,
                            post_tax_total_amount: {},
                            service_advisor_id: this.slotFormObject.service_advisor_id,
                            shop_id: this.slotFormObject.shop_id,
                            tekion_opcodes: [],
                            tekion_service_menu_id: null,
                            transportation_type_id: this.slotFormObject.transportation_type_id,
                            vehicle_id: null,
                            vehicle_make: null,
                            vehicle_model: null,
                            vehicle_year: null,
                        }
                } else  if (this.isEdit) {
                    this.formAppointment = {
                        appointment_date_time: parseInt(this.scopedAppointment.appointment_date_time, 10),
                        customer: this.currentCampaignCustomer || null,
                        customer_comments: this.scopedAppointment?.customer_comments || "",
                        delivery_contact: {
                            companyName: this.scopedAppointment?.delivery_contact?.companyName || null,
                            customerType: this.scopedAppointment?.delivery_contact?.customerType || null,
                            email: this.scopedAppointment?.delivery_contact?.email || null,
                            firstName: this.scopedAppointment?.delivery_contact?.firstName || null,
                            lastName: this.scopedAppointment?.delivery_contact?.lastName || null,
                            phones: [{
                                number: this.scopedAppointment?.delivery_contact?.phones?.[0]?.number || null,
                                phoneType: this.scopedAppointment?.delivery_contact?.phones?.[0]?.phoneType || null,
                            }]
                        },
                        notify_customer: this.scopedAppointment?.notify_customer || false,
                        post_tax_total_amount: {},
                        service_advisor_sid: this.scopedAppointment?.service_advisor_sid || "",
                        shop_sid: this.scopedAppointment?.shop_sid || "",
                        transportation_type_sid: this.scopedAppointment?.transportation_type_sid || "",
                        vehicle_id: this.scopedAppointment?.vehicle_id || 0,
                        vehicle_make: this.scopedAppointment?.vehicle_make || "",
                        vehicle_model: this.scopedAppointment?.vehicle_model || "",
                        vehicle_year: this.scopedAppointment?.vehicle_year || "",
                        delivery_contact_same_as_customer: this.scopedAppointment?.delivery_contact_same_as_customer || false,
                        // Unused feilds that can be added
                        // job_types: this.scopedAppointment?.jobs[0]?.type || "",
                        // tekion_opcodes: this.scopedAppointment?.jobs[0]?.operations[0]?.opcode,
                    }
                }
            },
            setDispositionDetails() {

                if (this.currentCampaignCustomer?.organization_time_zone_id <= 0) {
                    return;
                }

                const tekionAppointmentDetails = {
                    appointment_id: 0, // we set this upon successful create/update/cancel
                    appointment_datetime_date: this.convertToDateString(this.formAppointment.appointment_date_time),
                    appointment_datetime_time: this.convertToTimeString(this.formAppointment.appointment_date_time),
                    time_zone_id: this.currentCampaignCustomer.organization_time_zone_id,
                    special_instructions: this.formAppointment.customer_comments,
                }

                this.setTekionAppointmentDetails(tekionAppointmentDetails);
            },

        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            displayDateTime() {
                return this.convertAppointmentDateTimeFromMilliseconds(this.formAppointment.appointment_date_time)
            },
            eligibleToSave() {
                // Vehicle eligibility
                if (!(this.formAppointment?.vehicle_make?.length > 0)) {
                    return false;
                }
                if (!(this.formAppointment?.vehicle_model?.length > 0)) {
                    return false;
                }
                if (!(this.formAppointment?.vehicle_year?.length > 0)) {
                    return false;
                }

                // Job Type & Opcode eligibility
                if (!this.formAppointment.is_default_job) {
                    if (!(this.formAppointment?.job_type?.length > 0)) {
                        return false;
                    }

                    if (this.formAppointment.job_type == 'SERVICE_MENU') {
                        if (!this.formAppointment.tekion_service_menu_id) {
                            return false;
                        }
                    } else {
                        if (!(this.formAppointment?.tekion_opcodes?.length > 0)) {
                            return false;
                        }
                    }
                }

                // Delivery Contact eligibility
                if (!this.formAppointment.delivery_contact_same_as_customer) {
                    const dc = this.formAppointment.delivery_contact;

                    if (!(dc?.firstName?.length > 0)) return false;
                    if (!(dc?.lastName?.length > 0)) return false;
                    if (!(dc?.email?.length > 0)) return false;
                    if (!(dc?.phones?.[0]?.number?.length > 0)) return false;
                    if (!(dc?.phones?.[0]?.phoneType?.length > 0)) return false;
                    if (!(dc?.customerType?.length > 0)) return false;

                    // Ensure company name is present if customer type = BUSINESS
                    if (dc.customerType === 'BUSINESS') {
                        if (!(dc?.companyName?.length > 0)) return false;
                    }
                }

                return true;
            },
            isEdit() {
                return this.scopedAppointment?.tekion_appointment_id > 0;
            },
            hasDefaultOpCodes() {
                let hasDefaultOpCodes = false;
                if (this.tekionOpcodes?.length > 0) {
                    hasDefaultOpCodes = this.tekionOpcodes.some((opcode) => opcode.is_default);
                }
                return hasDefaultOpCodes;
            },
            showDeliveryContact() {
                return !this.formAppointment.delivery_contact_same_as_customer;
            },
            showJobsForm() {
                return !!this.formAppointment.vehicle_make && !!this.formAppointment.vehicle_model && !!this.formAppointment.vehicle_year;
            },
            showOpcodes() {
                return !this.opcodesLoading && this.tekionOpcodes?.length > 0 && this.validJobType && !this.formAppointment.is_default_job && this.formAppointment.job_type != 'SERVICE_MENU';
            },
            showServiceMenuForm() {
                return !this.serviceMenuLoading && !this.formAppointment.is_default_job && this.formAppointment.job_type && this.formAppointment.job_type == 'SERVICE_MENU';
            },
            validCustomerVehicles() {
                return this.customerVehicles?.length > 0;
            },
            validJobType() {
                return this.formAppointment?.job_type && this.formAppointment.job_type != "";
            },
            validOrganizationId() {
                return this.organizationId > 0;
            },
            validSelectedAppointmentDateTime() {
                return parseInt(this.formAppointment?.appointment_date_time) > 0;
            },
            validTekionOpcodes() {
                return this.tekionOpcodes?.length > 0;
            },
            vehicleLabel() {
                return (item) => `${item.vehicle_model_year} ${item.vehicle_make} ${item.vehicle_model}`;
            },
        },
        components: {
            DeliveryContact,
            StrLimitTooltipText, 
        },
        props: {
            convertAppointmentDateTimeFromMilliseconds: Function,
            createTekionAppointment: Function,
            currentCampaignCustomer: Object,
            customerVehicles: Array,
            scopedAppointment: Object,
            scopedSlot: Object,
            setTekionAppointmentDetails: Function,
            slotFormObject: Object,
            showSnackbar: Function,
            displayDetailsView: Function,
            displaySlotsView: Function,
            updateTekionAppointment: Function,
        },
        created() {
            this.initAppointment();
        },
        destroyed() {
        },
        watch: {
            'formAppointment.is_default_job': {
                handler(newValue) {
                    if (newValue) {
                        this.formAppointment.job_type = null;
                        this.formAppointment.tekion_opcodes = [];
                        this.formAppointment.tekion_service_menu_id = null;
                    }
                },
            },
            'formAppointment.job_type': {
                handler(newValue) {
                    if (!this.formAppointment.is_default_job) {
                        this.tekionOpcodes = [];
                        this.tekionServiceMenus = [];
                        this.formAppointment.tekion_opcodes = [];
                        this.formAppointment.tekion_service_menu_id = null;

                        // Call appropriate method based on job type
                        if (newValue == 'SERVICE_MENU') {
                            this.getTekionServiceMenus();
                        } else if (newValue == 'DEFAULT' || newValue == 'RECALL') {
                            this.getTekionOpcodes();
                        }
                    }
                },
                deep: true
            },
            "formAppointment.vehicle_id"() {
                if (this.formAppointment.vehicle_id) {
                    this.showVehicleForm = false;
                    if (this.validCustomerVehicles) {
                        for (let i = 0; i < this.customerVehicles.length; i++) {
                            if (this.customerVehicles[i].vehicle_id === this.formAppointment.vehicle_id) {
                                this.formAppointment.vehicle_year = this.customerVehicles[i].vehicle_model_year;
                                this.formAppointment.vehicle_make = this.customerVehicles[i].vehicle_make;
                                this.formAppointment.vehicle_model = this.customerVehicles[i].vehicle_model;
                                this.vehicleVin = this.customerVehicles[i].vin;
                                break;
                            }
                        }
                    }
                } else {
                    this.formAppointment.vehicle_year = null;
                    this.formAppointment.vehicle_make = null;
                    this.formAppointment.vehicle_model = null;
                    this.vehicleVin = null;
                }
            },
            hasDefaultOpCodes() {
                if (this.hasDefaultOpCodes) {
                    this.formAppointment.is_default_job = false;
                }
            },
            showVehicleForm() {
                if (this.showVehicleForm) {
                    this.formAppointment.vehicle_year = null;
                    this.formAppointment.vehicle_make = null;
                    this.formAppointment.vehicle_model = null;
                    this.formAppointment.vehicle_id = null;
                    this.vehicleVin = null;
                }
            },
            vehicleVin() {
                if (this.vehicleVin?.length == 17) {
                    this.jobs.push({key: "Service Menu", value: "SERVICE_MENU"})
                } else {
                    this.jobs.splice(2, 1); // remove the service menu option (only applicable if we have a valid vin)
                }
            },

            //Watch for scope slot change - if user updates, initially gets the value of the date_time from the scopedAppointment
            scopedSlot: {
                handler(newValue) {
                    Vue.nextTick(() => {
                        if (newValue?.startTime) {
                            this.formAppointment.appointment_date_time = newValue.startTime;
                            //May want to use this if they're changing shops or transportation type but this seems unlikely
                            // this.formAppointment.transportation_type_sid = this.slotFormObject?.tekion_transportation_type_sid
                            // this.formAppointment.shop_sid = this.slotFormObject?.tekion_shop_sid
                            // this.formAppointment.service_advisor_sid = this.slotFormObject?.tekion_service_advisor_sid
                        }
                    });
                },
                deep: true,
                immediate: true

            },
        },
    });
</script>

<style lang="scss" scoped>

</style>
