<template lang="pug">
    .ma-md-4
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-alert(
            v-else-if="renderCampaignDispositionError"
            dark
            dense
            type="error"
        )
            | Unable to find any dispositions

        v-container.overflow-y-auto(v-else)
            v-row(align="center")
                v-col(cols='12')
                    v-form
                        v-autocomplete(
                            :items='campaignDispositions'
                            item-text="disposition_description"
                            item-value="campaign_disposition_id"
                            label='Disposition'
                            outlined
                            prepend-inner-icon="mdi-pencil"
                            v-model="communicationDisposition.campaign_disposition_id"
                            v-on:change="handleCampaignDispositionSelectChange"
                            dense
                        )

                        v-textarea(
                            v-model="communicationDisposition.notes"
                            label='Notes'
                            rows="1"
                            auto-grow
                            clearable
                            counter
                            outlined
                            dense
                            prepend-inner-icon="mdi-text"
                        )

                        div(v-if="renderAppointmentForm")
                            v-divider.divider-spacing
                            .text-subtitle-2.subtitle-2-padding.text-center
                                | Schedule Appointment

                            AppointmentForm(
                                :appointment-scheduling-hour="currentAppointmentSchedulingHour"
                                :autobuy-communication-disposition="autobuyCommunicationDisposition"
                                :on-appointment-change="onAppointmentChange"
                                :time-zone-id="customerTimeZoneId"
                                :tekion-appointment-details="tekionAppointmentDetails"
                                :use-on-change="true"
                                :valid-customer="validCampaignCustomerActive"
                            )

                            v-divider.divider-spacing

                        div(v-else-if="renderAppointmentRescheduleForm")
                            v-divider.divider-spacing
                            .text-subtitle-2.subtitle-2-padding.text-center
                                | Reschedule Appointment

                            AppointmentRescheduleForm(
                                :on-appointment-change="onAppointmentChange"
                                :time-zone-id="customerTimeZoneId"
                                :tekion-appointment-details="tekionAppointmentDetails"
                                :upcoming-appointments="rescheduleableAppointments"
                                :valid-customer="validCampaignCustomerActive"
                            )

                            v-divider.divider-spacing

                        div(v-else-if="renderAppointmentCanceledForm")
                            v-divider.divider-spacing
                            .text-subtitle-2.subtitle-2-padding.text-center
                                | Cancel Appointment

                            AppointmentCancelForm(
                                :on-appointment-change="onAppointmentChange"
                                :tekion-appointment-details="tekionAppointmentDetails"
                                :upcoming-appointments="upcomingAppointments"
                                :valid-customer="validCampaignCustomerActive"
                            )

                            v-divider.divider-spacing

                        div(v-if="renderScheduledCommunicationForm")
                            v-divider.divider-spacing
                            .text-subtitle-2.subtitle-2-padding.text-center
                                | Schedule next communication

                            ScheduledCommunicationForm(
                                :campaign-id="campaignID"
                                :communication-manager="communicationManager"
                                :default-communication-type="communicationType"
                                :hide-communication-type-select="true"
                                :on-scheduled-communication-change="onScheduledCommunicationChange"
                                :time-zone-id="customerTimeZoneId"
                                :use-on-change="true"
                                :valid-customer="validCampaignCustomerActive"
                            )

                            v-divider.divider-spacing

                        .text-right
                            v-btn.mr-4(
                                @click='resetForm'
                                :x-small="renderPhoneActionButtons && selectedDispositionHasSmsMessageFollowUp"
                                :small="renderPhoneActionButtons && !selectedDispositionHasSmsMessageFollowUp"
                            )
                                | Reset

                            v-btn.mr-4(
                                v-if="renderCreateButton"
                                color='success'
                                @click='createCommunicationDisposition()'
                                :disabled="!isOkayToCreateCommunicationDisposition"
                            )
                                | Save

                            template(v-else-if="renderPhoneActionButtons")
                                v-btn.mr-4(
                                    v-if="renderSaveStopButton"
                                    color='error'
                                    :x-small="selectedDispositionHasSmsMessageFollowUp"
                                    :small="!selectedDispositionHasSmsMessageFollowUp"
                                    @click="startSaveAndNext(saveAndStopAction);"
                                    :disabled="!isOkayToCreateCommunicationDisposition"
                                )
                                    div(v-if="selectedDispositionHasSmsMessageFollowUp")
                                        | Save & Send SMS Follow Up
                                        v-icon(small) mdi-arrow-right-thick
                                        | Stop
                                    div(v-else)
                                        | Save
                                        v-icon(small) mdi-arrow-right-thick
                                        | Stop

                                v-btn.mr-4(
                                    v-if="renderSaveContinueButton"
                                    color='success'
                                    :x-small="selectedDispositionHasSmsMessageFollowUp"
                                    :small="!selectedDispositionHasSmsMessageFollowUp"
                                    @click="startSaveAndNext('continue');"
                                    :disabled="!isOkayToCreateCommunicationDisposition"
                                )
                                    div(v-if="selectedDispositionHasSmsMessageFollowUp")
                                        | Save & Send SMS Follow Up
                                        v-icon(small) mdi-arrow-right-thick
                                        | Survey
                                    div(v-else)
                                        | Save
                                        v-icon(small) mdi-arrow-right-thick
                                        | Survey

                                v-btn.mr-4(
                                    v-else
                                    color='success'
                                    :x-small="selectedDispositionHasSmsMessageFollowUp"
                                    :small="!selectedDispositionHasSmsMessageFollowUp"
                                    @click="startSaveAndNext('continue_and_start');"
                                    :disabled="!isOkayToCreateCommunicationDisposition"
                                )
                                    div(v-if="selectedDispositionHasSmsMessageFollowUp")
                                        | Save & Send SMS Follow Up
                                        v-icon(small) mdi-arrow-right-thick
                                        | Dial Next
                                    div(v-else)
                                        | Save
                                        v-icon(small) mdi-arrow-right-thick
                                        | Dial Next

        v-divider.divider-spacing

        v-container.mx-auto
            .text-subtitle-2.subtitle-2-padding.text-center
                | Previous Dispositions

            CommunicationDispositionDetails(
                v-if="validCommunicationDispositions"
                v-for='communicationDisposition in communicationDispositions'
                :key='communicationDisposition.communication_disposition_id'
                :communication-disposition="communicationDisposition"
                :show-campaign="false"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCommunicationDispositions && !isLoadingCommunicationDispositions")
                | No dispositions submitted for this enrollment

            .text-center(v-if="isLoadingCommunicationDispositions")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 100px;"
                )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import AppointmentCancelForm from '@/components/appointments/cancel.vue';
    import AppointmentForm from '@/components/appointments/form.vue';
    import AppointmentRescheduleForm from '@/components/appointments/reschedule_form.vue';
    import CommunicationDispositionDetails from '@/components/communication_dispositions/communication_disposition_details.vue';
    import ScheduledCommunicationForm from '@/components/scheduled_communications/form.vue';

    // Data
    import {
        CreateCommunicationDisposition,
        GetAppointmentHistory,
        GetAppointmentSchedulingHours,
        GetCampaignDispositions,
        GetCommunicationDispositions,
    } from "@/data";

    // Helpers
    import { jsDayToFriendly } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                appointment: null,
                appointmentSchedulingHour: null,
                bus: VolieState.websocketClient().eventBus(),
                campaignDispositions: [],
                communicationDisposition: {},
                communicationDispositions: [],
                isLoading: true, // default to true so the tab shows the loader from the start rather than the error alert before initial search
                isLoadingCommunicationDispositions: false,
                loadedAppointmentSchedulingHours: false,
                pastAppointments: [],
                rescheduleableAppointments: [],
                scheduledCommunication: {
                    assignment_id: null,
                    assignment_type: null,
                    communication_type: null,
                    notes: null,
                    preference_id: null,
                    preference_type: null,
                    scheduled_time_date: null,
                    scheduled_time_time: null,
                    time_zone_id: null,
                },
                selectedCampaignDisposition: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                upcomingAppointments: [],
            }
        },
        methods: {
            buildRescheduleableAppointments() {

                // Reset
                this.rescheduleableAppointments = [];

                // Ensure past and/or upcoming are present
                if (!this.validPastAppointments && !this.validUpcomingAppointments) {
                    return;
                }

                // Set all upcoming appointments
                this.rescheduleableAppointments = (this.upcomingAppointments || []);

                // If account has appt no show reschedule window days > 0, see if any fall in the window
                if (this.accountAppointmentNoShowRescheduleWindowDays === 0 || !this.validPastAppointments) {
                    return;
                }

                // Loop over past appointments and see if any are in the window
                for (let i = 0; i < this.pastAppointments.length; i++) {

                    // Init
                    const appointment = this.pastAppointments[i];
                    if (!(appointment?.appointment_id > 0)) {
                        continue;
                    }

                    // Check if inside window
                    if (!appointment.is_inside_no_show_reschedule_window) {
                        continue;
                    }

                    // Append to slice
                    this.rescheduleableAppointments.push(appointment);
                }
            },
            createAgentForSavedDisposition(communicationDisposition) {
                if (!(communicationDisposition?.communication_disposition_id > 0) || !(this.communicationType?.length > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: communicationDisposition.campaign_customer_id,
                    campaign_id: communicationDisposition.campaign_id,
                    immediately_move_to_status: null,
                    noticeable_id: communicationDisposition.communication_disposition_id,
                    noticeable_type: "communication_disposition",
                    organization_id: communicationDisposition.organization_id,
                    status_name: null,
                }

                // Switch over communication type
                switch (this.communicationType) {
                    case "Email":
                        options.status_name = "Email - Saved Disposition";
                        break;

                    case "Phone":
                        options.status_name = "Finished CampaignCustomer";
                        break;

                    case "SMS Message":
                        options.status_name = "SMS Message - Saved Disposition";
                        break;

                    default: // non-supported communication type
                        return;
                }

                this.createAgent(options);
            },
            createCommunicationDisposition() {

                console.log("createCommunicationDisposition: 1");
                if (!this.isOkayToCreateCommunicationDisposition) {
                    return;
                }

                console.log("createCommunicationDisposition: 2");
                // If cc active is missing, agent MUST have reception mode enabled in order to move on without a customer
                if (!(this.currentCampaignCustomer?.campaign_customer_active_id > 0)) {

                    console.log("createCommunicationDisposition: 3");
                    // If the agent doesn't have reception mode enabled, need to issue error and abort
                    if (!this.receptionModeEnabled) {
                        console.log("createCommunicationDisposition: 4");
                        this.showSnackbar("error", "You must find or create a customer first");
                        return;
                    }
                }

                const options = {
                    appointment: this.appointment,
                    autobuy: this.autobuyCommunicationDisposition,
                    communication_disposition: {
                        campaign_customer_active_id: (this.currentCampaignCustomer.campaign_customer_active_id || 0),
                        campaign_customer_id: (this.currentCampaignCustomer.campaign_customer_id || 0),
                        campaign_disposition_id: this.communicationDisposition.campaign_disposition_id,
                        communication_channel: null,
                        communication_id: null,
                        notes: this.communicationDisposition.notes,
                        voice_mail_recording_id: (this.voiceMailRecording?.voice_mail_recording_id || 0),
                    },
                    saved_place_scheduled_communication_id: (this.pendingSavedPlaceCall?.scheduled_communication_id || 0),
                    scheduled_communication: {
                        assignment_id: (this.scheduledCommunication?.assignment_id || 0),
                        assignment_type: this.scheduledCommunication?.assignment_type,
                        communication_type: this.scheduledCommunication?.communication_type,
                        notes: this.scheduledCommunication?.notes,
                        preference_id: (this.scheduledCommunication?.preference_id || 0),
                        preference_type: this.scheduledCommunication?.preference_type,
                        scheduled_time_date: this.scheduledCommunication?.scheduled_time_date,
                        scheduled_time_time: this.scheduledCommunication?.scheduled_time_time,
                        time_zone_id: this.scheduledCommunication?.time_zone_id,
                    },
                    tekion: this.tekionAppointmentDetails,
                }

                // Set communication channel if applicable
                if (this.communicationType?.length > 0) {

                    // Switch over type
                    switch(this.communicationType) {
                    case "Phone":
                        options.communication_disposition.communication_channel = "call";
                        options.communication_disposition.communication_id = this.callId;
                        break;

                    }
                }

                console.log("createCommunicationDisposition: 5");
                this.isLoading = true;
                CreateCommunicationDisposition(options).then((response) => {
                    if (response?.Data?.communication_disposition?.communication_disposition_id > 0) {
                        this.createAgentForSavedDisposition(response.Data.communication_disposition);
                        this.resetForm();
                        this.initNewCommunicationDisposition();
                        this.showSnackbar("green", "Successfully saved disposition");

                        // Set on current
                        if (this.setChangeOnCurrentCampaignCustomer !== null && this.setChangeOnCurrentCampaignCustomer !== undefined && response?.Data?.total_communication_dispositions > 0) {
                            this.setChangeOnCurrentCampaignCustomer("total_communication_dispositions", response.Data.total_communication_dispositions);
                        }

                        // In case of opt in out via disposition, set subscription changes on communication manager
                        if (this.setSubscriptionChangesOnCommunicationManager !== null && this.setSubscriptionChangesOnCommunicationManager !== undefined) {
                            this.setSubscriptionChangesOnCommunicationManager(response);
                        }

                        this.onNewCommunicationDisposition(response);

                        // If the create was initiated from the parent, need to also create survey attempt
                        if (this.initiateCreateCommunicationDisposition === true) {
                            this.createSurveyAttempt(response?.Data?.communication_disposition?.communication_disposition_id);
                        }
                    } else {
                        // todo - figure out what to do on failure. Currently for phone calls with the screen overlay it just spins if this fails
                    }
                    this.isLoading = false;
                });
            },
            getAppointmentSchedulingHours() {

                // Ensure not already run
                if (this.loadedAppointmentSchedulingHours) {
                    return;
                }

                // Verify valid communication disposition
                if (!(this.selectedCampaignDisposition?.campaign_disposition_id > 0)) {
                    return;
                }

                // Ensure new appt or reschedule
                if (!this.renderAppointmentForm && !this.renderAppointmentRescheduleForm) {
                    return;
                }

                // Load them
                const options = {
                    ownable_id: (this.currentCampaignCustomer?.campaign_id || 0),
                    ownable_type: "campaign",
                }

                GetAppointmentSchedulingHours(options).then((response) => {
                    if (response?.Data?.appointment_scheduling_hours?.length === 1) {
                        this.appointmentSchedulingHour = response.Data.appointment_scheduling_hours[0];
                    }

                    // Set to true so no matter what we don't run again
                    // todo - double check inbound call with campaign update logic
                    this.loadedAppointmentSchedulingHours = true;
                });
            },
            getCampaignDispositions() {
                if (!(this.currentCampaignCustomer?.campaign_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_active_id: (this.currentCampaignCustomer.campaign_customer_active_id || 0),
                    campaign_id: this.currentCampaignCustomer.campaign_id,
                }

                this.isLoading = true;
                GetCampaignDispositions(options).then((response) => {
                    if (response?.Data?.campaign_dispositions?.length > 0) {
                        this.campaignDispositions = response.Data.campaign_dispositions;
                    }

                    this.isLoading = false;
                });
            },
            getCommunicationDispositions() {
                if (!(this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.campaign_customer_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    customer_id:          this.currentCampaignCustomer.customer_id,
                }

                this.isLoading = true;
                GetCommunicationDispositions(options).then((response) => {
                    if (response?.Data?.communication_dispositions?.length > 0) {
                        this.communicationDispositions = response.Data.communication_dispositions;
                    }

                    this.isLoading = false;
                });
            },
            getUpcomingAppointments() {
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                const options = {
                    customer_id: this.currentCampaignCustomer.customer_id,
                }

                this.isLoading = true;
                GetAppointmentHistory(options).then((response) => {
                    if (response?.Data?.past_appointments?.length > 0 || response?.Data?.upcoming_appointments?.length > 0) {
                        // Set
                        this.pastAppointments = response.Data.past_appointments;
                        this.upcomingAppointments = response.Data.upcoming_appointments;

                        // Setup upcoming and missed slice
                        this.buildRescheduleableAppointments();
                    }
                    this.isLoading = false;
                });
            },
            handleCampaignDispositionSelectChange() {

                // Reset objects
                this.appointment = null;
                // this.scheduledCommunication = null;

                if (this.communicationDisposition?.campaign_disposition_id > 0) {
                    this.selectCampaignDisposition();
                }
            },
            initNewCommunicationDisposition() {
                this.communicationDisposition = {
                    campaign_disposition_id: null,
                    notes: this.parentDispositionNotes?.length > 0 ? this.parentDispositionNotes : null,
                };
            },
            onNewCommunicationDisposition(response) {

                // Verify legit communication disposition
                const data = response?.Data;
                const communicationDisposition = data?.communication_disposition;
                if (!(communicationDisposition?.communication_disposition_id > 0) || !(communicationDisposition?.campaign_customer_active_id > 0) || !(communicationDisposition?.account_id > 0)) {
                    return;
                }

                // Verify the campaign customer is the same and if so, set total
                if (this.setChangeOnCurrentCampaignCustomer !== null && this.setChangeOnCurrentCampaignCustomer !== undefined && this.currentCampaignCustomer?.campaign_customer_active_id === communicationDisposition.campaign_customer_active_id) {
                    this.setChangeOnCurrentCampaignCustomer("total_communication_dispositions", (data?.total_communication_dispositions || 0));

                    if (communicationDisposition?.communication_disposition_id > 0) {

                        // Verify new data to current screen
                        if (this.communicationDispositions?.length > 0) {
                            const existingNewCommunicationDisposition = this.communicationDispositions.find(x => x.communication_disposition_id === communicationDisposition.communication_disposition_id);

                            // Return if new communication disposition already in array
                            if (existingNewCommunicationDisposition?.communication_disposition_id > 0) {
                                return;
                            }
                        }

                        // Insert new communication disposition to front of communicationDispositions array
                        this.communicationDispositions.unshift(communicationDisposition);
                    }
                }
            },
            onAppointmentChange(appointment) {

                // Verify
                if (!appointment) {
                    return;
                }

                // Set appointment
                this.appointment = appointment;
            },
            onScheduledCommunicationChange(scheduledCommunication) {

                // Verify
                if (!scheduledCommunication) {
                    return;
                }

                // Set details
                this.scheduledCommunication.assignment_id = scheduledCommunication.assignment_id;
                this.scheduledCommunication.assignment_type = scheduledCommunication.assignment_type;
                this.scheduledCommunication.communication_type = scheduledCommunication.communication_type;
                this.scheduledCommunication.notes = scheduledCommunication.notes;
                this.scheduledCommunication.preference_id = scheduledCommunication.preference_id;
                this.scheduledCommunication.preference_type = scheduledCommunication.preference_type;
                this.scheduledCommunication.scheduled_time_date = scheduledCommunication.scheduled_time_date;
                this.scheduledCommunication.scheduled_time_time = scheduledCommunication.scheduled_time_time;
                this.scheduledCommunication.time_zone_id = scheduledCommunication.time_zone_id;
            },
            resetForm() {
                this.appointment = null;
                this.selectedCampaignDisposition = null;
                this.communicationDisposition = {};
            },
            selectCampaignDisposition() {
                if (!this.validCampaignDispositions || !(this.communicationDisposition?.campaign_disposition_id > 0)) {
                    return;
                }

                for (let i = 0; i < this.campaignDispositions.length; i++) {
                    const campaignDisposition = this.campaignDispositions[i];
                    if (campaignDisposition?.campaign_disposition_id === this.communicationDisposition?.campaign_disposition_id) {
                        this.selectedCampaignDisposition = campaignDisposition;
                        break;
                    }
                }

                // Handle necessary data loads
                if (this.selectedCampaignDisposition?.campaign_disposition_id > 0) {

                    // For appt reschedule or cancel, need to load upcoming appointments
                    if (this.selectedCampaignDisposition.denotes_appointment_reschedule === true || this.selectedCampaignDisposition.denotes_appointment_cancel === true) {
                        this.getUpcomingAppointments();
                    }
                }
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            accountAppointmentNoShowRescheduleWindowDays() {
                return this.currentUser?.account?.appointment_no_show_reschedule_window_days || 0;
            },
            accountPhoneSaveNext() {
                return this.currentUser?.account?.phone_save_next;
            },
            accountPhoneSaveStop() {
                return this.currentUser?.account?.phone_save_stop;
            },
            campaignID() {
                return this.currentCampaignCustomer?.campaign_id;
            },
            currentAppointmentSchedulingHour() {

                // Verify
                if (!(this.appointmentSchedulingHour?.days?.length > 0) || !(this.appointment?.appointment_datetime_date?.length > 0)) {
                    return null;
                }

                // Init current time in local
                const localNow = new Date(this.appointment.appointment_datetime_date);

                // Convert to utc so it matches date selected
                const current = new Date(localNow.getTime() + localNow.getTimezoneOffset() * 60000);

                // Loop over and find matching day
                for (let i = 0; i < this.appointmentSchedulingHour.days.length; i++) {

                    // Init day
                    const day = this.appointmentSchedulingHour.days[i];
                    if (day?.day !== jsDayToFriendly(current.getDay())) {
                        continue;
                    }

                    return day;
                }

                return null;
            },
            currentUser() {
                return VolieState.currentUser();
            },
            customerTimeZoneId() {

                // Try and use the customer's time zone
                if (this.currentCampaignCustomer?.customer?.time_zone_id > 0) {
                    return this.currentCampaignCustomer.customer.time_zone_id;
                }

                // Now try to use the organization's time zone
                if (this.currentCampaignCustomer?.organization_time_zone_id > 0) {
                    return this.currentCampaignCustomer?.organization_time_zone_id;
                }

                return -1;
            },
            isOkayToCreateCommunicationDisposition() {
                if (this.communicationDisposition?.campaign_disposition_id > 0 && this.communicationDisposition?.campaign_disposition_id === this.selectedCampaignDisposition?.campaign_disposition_id) {

                    // If communication type is "Phone", callID MUST be present if the call has not failed
                    if (this.communicationType === "Phone" && !(this.callId > 0) && !this.callFailed) {
                        return false;
                    }

                    // If disposition requires a new appointment, verify it is present
                    if ((this.renderAppointmentForm || this.renderAppointmentRescheduleForm) && !this.validNewAppointment) {
                        return false;
                    }

                    // Verify for rescheduled appt that the datetime changed
                    if (this.renderAppointmentRescheduleForm && !(this.appointment?.datetime_changed === true)) {
                        return false;
                    }

                    // If disposition requires an existing appointment, verify it is present
                    if (this.renderAppointmentCanceledForm && !(this.appointment?.appointment_id > 0)) {
                        return false;
                    }

                    // If disposition requires a scheduled communication, verify it is present
                    if (this.renderScheduledCommunicationForm) {

                        // If any required values are null, disable button
                        if (this.scheduledCommunication.scheduled_time_date === null || this.scheduledCommunication.scheduled_time_time === null || this.scheduledCommunication.time_zone_id === null) {
                            return false;
                        }
                    }

                    return true;
                }

                return false;
            },
            receptionModeEnabled() {
                return this.currentUser?.account_user?.reception_mode_enabled === true;
            },
            renderAppointmentForm() {
                return this.selectedCampaignDisposition?.campaign_disposition_id > 0 && this.selectedCampaignDisposition?.denotes_appointment === true;
            },
            renderCampaignDispositionError() {
                return (!this.validCampaignDispositions && !this.isLoading);
            },
            renderCreateButton() {
                return this.communicationType !== "Phone";
            },
            renderPhoneActionButtons() {
                return this.communicationType === "Phone" && (this.validCampaignCustomerActive || this.receptionModeEnabled);
            },
            renderSaveContinueButton() {
                return this.renderPhoneActionButtons && (this.isManualMode || this.accountPhoneSaveNext === "continue" || this.validVoiceMailRecording || this.validPausedVoiceMailRecording);
            },
            renderSaveStopButton() {
                return this.renderPhoneActionButtons && !this.isManualMode && !this.validVoiceMailRecording && !this.validPausedVoiceMailRecording;
            },
            renderScheduledCommunicationForm() {
                return this.selectedCampaignDisposition?.campaign_disposition_id > 0 && this.selectedCampaignDisposition?.denotes_callback === true && this.communicationType === "Phone";
            },
            renderAppointmentCanceledForm() {
                return this.selectedCampaignDisposition?.campaign_disposition_id > 0 && this.selectedCampaignDisposition?.denotes_appointment_cancel === true;
            },
            renderAppointmentRescheduleForm() {
                return this.selectedCampaignDisposition?.campaign_disposition_id > 0 && this.selectedCampaignDisposition?.denotes_appointment_reschedule === true;
            },
            saveAndStopAction() {
                return this.accountPhoneSaveStop === "continue" ? "continue" : "stop";
            },
            selectedDispositionHasSmsMessageFollowUp() {
                return this.selectedCampaignDisposition?.campaign_disposition_id > 0 && this.selectedCampaignDisposition?.has_sms_message_follow_up === true;
            },
            validCampaignCustomerActive() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0;
            },
            validCampaignDispositions() {
                return (this.campaignDispositions?.length > 0);
            },
            validCommunicationDispositions() {
                return (this.communicationDispositions?.length > 0);
            },
            validNewAppointment() {

                // For reschedule appt, appointment ID must be present
                if (this.renderAppointmentRescheduleForm && !(this.appointment?.appointment_id > 0)) {
                    return false;
                }

                return !!this.appointment?.appointment_datetime_date && !!this.appointment?.appointment_datetime_time && this.appointment?.time_zone_id > 0;
            },
            validPastAppointments() {
                return this.pastAppointments?.length > 0;
            },
            validPausedVoiceMailRecording() {
                return this.isPausedVoiceMailRecording === true;
            },
            validUpcomingAppointments() {
                return this.upcomingAppointments?.length > 0;
            },
            validVoiceMailRecording() {
                return this.voiceMailRecording?.voice_mail_recording_id > 0;
            },
        },
        components: {
            AppointmentCancelForm,
            AppointmentForm,
            AppointmentRescheduleForm,
            CommunicationDispositionDetails,
            ScheduledCommunicationForm,
        },
        props: {
            autobuyCommunicationDisposition: Object,
            callFailed: Boolean,
            callId: Number,
            communicationManager: Object,
            communicationType: String,
            createAgent: Function,
            createSurveyAttempt: Function,
            currentCampaignCustomer: Object,
            initiateCreateCommunicationDisposition: Boolean,
            isManualMode: Boolean,
            isPausedVoiceMailRecording: Boolean,
            parentDispositionNotes: String,
            pendingSavedPlaceCall: Object,
            setChangeOnCurrentCampaignCustomer: Function,
            setIsOkayToCreateCommunicationDisposition: Function,
            setSelectedCampaignDisposition: Function,
            setSubscriptionChangesOnCommunicationManager: Function,
            startSaveAndNext: Function,
            syncDispositionNotes: Function,
            tekionAppointmentDetails: Object,
            voiceMailRecording: Object,
        },
        watch: {
            "communicationDisposition.notes"() {
                if (this.syncDispositionNotes !== undefined) {
                    this.syncDispositionNotes(this.communicationDisposition?.notes);
                }
            },
            initiateCreateCommunicationDisposition: {
                deep: true,
                immediate: true,
                handler() {
                    // If initiate is now true, create
                    if (this.initiateCreateCommunicationDisposition === true) {
                        this.createCommunicationDisposition();
                    }
                },
            },
            isOkayToCreateCommunicationDisposition() {
                this.setIsOkayToCreateCommunicationDisposition(this.isOkayToCreateCommunicationDisposition);
            },
            parentDispositionNotes() {
                if (!!this.communicationDisposition && this.parentDispositionNotes !== this.communicationDisposition.notes) {
                    this.communicationDisposition.notes = this.parentDispositionNotes;
                }
            },
            selectedCampaignDisposition: {
                deep: true,
                immediate: true,
                handler() {
                    this.setSelectedCampaignDisposition(this.selectedCampaignDisposition);
                    this.getAppointmentSchedulingHours();
                },
            },
        },
        created() {
            this.initNewCommunicationDisposition();
            this.getCampaignDispositions();
            this.getCommunicationDispositions();
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationDisposition" }}, this.onNewCommunicationDisposition);
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationDisposition" }}, this.onNewCommunicationDisposition);
        },
    });
</script>

<style lang="scss" scoped>
</style>
