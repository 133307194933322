<template lang="pug">

    div.width-100-percent
        div
            v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

        v-layout
            v-row.max-width-100-percent.mx-0(dense)
                v-col.col-right-divider(cols='4')
                    v-list(dense expand)
                        v-list-group(:value='panelDetailsOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Details

                            v-list-item.width-100-percent(style='padding-left: 5px !important;')
                                v-simple-table.width-100-percent
                                    template(v-slot:default)
                                        tbody(v-if='validConference' )
                                            tr(v-if="renderSharedLinks")
                                                td
                                                    SharedLinksDialog(
                                                        v-if="renderSharedLinks"
                                                        :account-id="conference.account_id"
                                                        component="conference_show_full"
                                                        :shareable-id="conference.conference_id"
                                                        shareable-type="conference"
                                                    )
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Start Time
                                                    | {{ conference.friendly_start_time ? conference.friendly_start_time : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Organization
                                                    | {{ conference.organization_name ? conference.organization_name : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign
                                                    | {{ conference.campaign_name ? conference.campaign_name : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Customer
                                                    template(v-if='conference.customer_full_name && conference.customer_key')
                                                        router-link(:to="{ name: 'customers_show', params: { customer_key: conference.customer_key } }" target="_blank") {{ conference.customer_full_name }}
                                                    template(v-else) ---
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Agent
                                                    | {{ conference.user_full_name ? conference.user_full_name : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Disposition
                                                    | {{ conference.disposition_description ? conference.disposition_description : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Direction
                                                    | {{ conference.friendly_direction ? conference.friendly_direction : '---' | titleize }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 To Number
                                                    template(v-if="conference.friendly_to_number") {{ conference.friendly_to_number | tel }}
                                                    template(v-else) ---
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 From Number
                                                    template(v-if="conference.friendly_from_number") {{ conference.friendly_from_number | tel }}
                                                    template(v-else) ---

                                        tbody(v-if='isLoading === true')
                                            tr
                                                td.text-center
                                                    v-progress-circular(
                                                        :size='75'
                                                        :width='7'
                                                        color='#0f5b9a'
                                                        indeterminate
                                                        style="margin-top: 75px; margin-bottom: 75px;"
                                                    )

                        v-list-group(:value='panelInsightOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Insights

                            v-list-item.width-100-percent(style='padding-left: 5px !important;')
                                v-simple-table.width-100-percent
                                    template(v-slot:default)
                                        tbody(v-if='validConference')
                                            tr
                                                td 
                                                    v-chip(v-for="item in conferenceInsightsChipItems" :key="item" class="ma-1" color="primary" :ripple="false") {{ item }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Call Duration
                                                    | {{ conference.duration | friendlyDurationFormat }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Hold Duration
                                                    | {{ conference.hold_duration | friendlyDurationFormat }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Agent Talk Duration
                                                    | {{ conference.agent_talk_duration | friendlyDurationFormat }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Wrap Up Duration
                                                    | {{ conference.wrap_up_duration | friendlyDurationFormat }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Completion Code
                                                    template(v-if="conference.campaign_customer_completion_code === 1") Final Disposition
                                                    template(v-else-if="conference.campaign_customer_completion_code === 2") Max Attempts
                                                    template(v-else-if="conference.campaign_customer_completion_code === 3") Voicemail One and Done
                                                    template(v-else) ---
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Disposition
                                                    | {{ conference.disposition_description ? conference.disposition_description : '---' }}
                                            tr
                                                td.no-wrap.xs-width
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Follow Up
                                                    template(v-if="conference.follow_up_key")
                                                        | {{ conference.follow_up_key | titleize }}
                                                    v-icon.error--text(v-else) mdi-close
                                        tbody(v-if='isLoading === true')
                                            tr
                                                td.text-center
                                                    v-progress-circular(
                                                        :size='75'
                                                        :width='7'
                                                        color='#0f5b9a'
                                                        indeterminate
                                                        style="margin-top: 75px; margin-bottom: 75px;"
                                                    )

                        v-list-group(:value='panelKeysOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Keys

                            v-list-item.width-100-percent(style='padding-left: 5px !important;')
                                v-simple-table.width-100-percent
                                    template(v-slot:default)
                                        tbody(v-if='validConference')
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Conference Key
                                                    | {{ conference.conference_key ? conference.conference_key : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Call Key
                                                    | {{ conference.call_key ? conference.call_key : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign Customer Key
                                                    | {{ conference.campaign_customer_key ? conference.campaign_customer_key : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Survey Attempt Key
                                                    | {{ conference.survey_attempt_key ? conference.survey_attempt_key : '---' }}
                                            tr
                                                td
                                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Conference SID
                                                    | {{ conference.conference_sid ? conference.conference_sid : '---' }}

                                        tbody(v-if='isLoading === true')
                                            tr
                                                td.text-center
                                                    v-progress-circular(
                                                        :size='75'
                                                        :width='7'
                                                        color='#0f5b9a'
                                                        indeterminate
                                                        style="margin-top: 75px; margin-bottom: 75px;"
                                                    )

                        v-list-group(:value='panelDispositionOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Disposition

                            v-list-item.width-100-percent(style='padding-left: 10px !important;')
                                v-form(v-if='validSurveyAttempt')
                                    v-container.overflow-y-auto
                                        v-row(align="center")
                                            v-col.pb-0(cols='12')                                    
                                                v-autocomplete(
                                                    :items='conference.campaign_dispositions'
                                                    item-text="disposition_description"
                                                    item-value="disposition_id"
                                                    label='Disposition'
                                                    outlined
                                                    prepend-inner-icon="mdi-pencil"
                                                    v-model="conference.disposition_id"
                                                    dense
                                                    :readonly="hideCreateUpdateDestroyButtons"
                                                )

                                            v-col.pb-0.pt-0(cols='12')
                                                v-textarea(
                                                    v-model="conference.survey_attempt_notes"
                                                    label='Notes'
                                                    rows="1"
                                                    auto-grow
                                                    clearable
                                                    counter
                                                    outlined
                                                    dense
                                                    prepend-inner-icon="mdi-text"
                                                    :readonly="hideCreateUpdateDestroyButtons"
                                                )

                                            v-col.pt-0(v-if='!hideCreateUpdateDestroyButtons' cols='12')
                                                v-btn.mr-4(
                                                    color='success'
                                                    @click='updateSurveyAttempt()'
                                                    :disabled="!eligibleToSaveSurveyAttempt"
                                                    outlined
                                                )
                                                    v-icon mdi-content-save
                                                    | Save

                                v-simple-table.width-100-percent(v-else-if='isLoading')
                                    template(v-slot:default)
                                        tbody
                                            tr
                                                td.text-center
                                                    v-progress-circular(
                                                        :size='75'
                                                        :width='7'
                                                        color='#0f5b9a'
                                                        indeterminate
                                                        style="margin-top: 75px; margin-bottom: 75px;"
                                                    )

                                v-alert(v-else outlined type='info' dense border='left' style="margin: 10px 40px 10px 20px; width: 100%;")
                                    | Unable to find survey attempt disposition.

                        v-list-group(:value='panelScorecardOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Scorecards

                            v-list-item.width-100-percent(style='padding-left: 10px !important;')
                                Scorecards(
                                    :conference-id="conference.conference_id"
                                    :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                                    :show-snackbar="showSnackbar"
                                )

                        v-list-group(:value='panelSurveyOpen' no-action)
                            template(v-slot:activator)
                                v-list-item-content(style="height: 48px;")
                                    v-list-item-title Survey

                            v-list-item.width-100-percent(style='padding-left: 10px !important;')
                                v-card.mx-lg-auto(v-if="validSurvey" style="max-width: 100%; width: 100%; padding: 10px; padding-left: 26px; margin-top: 10px; margin-bottom: 10px;")
                                    SurveyForm(
                                        v-if="validSurveyAnswers"
                                        v-for='(question, i) in conference.survey_answers'
                                        :key='i'
                                        :readonly="hideCreateUpdateDestroyButtons"
                                        :set-survey-answer="setSurveyAnswer"
                                        :survey-template-question="question"
                                    )

                                    v-row(v-if='!hideCreateUpdateDestroyButtons')
                                        v-col(cols='12')
                                            v-btn.mr-4(
                                                color='success'
                                                @click='createOrUpdateSurveyAnswers()'
                                                :disabled="!eligibleToSaveSurveyAnswers"
                                                outlined
                                            )
                                                v-icon mdi-content-save
                                                | Save

                                v-simple-table.width-100-percent(v-else-if='isLoading')
                                    template(v-slot:default)
                                        tbody
                                            tr
                                                td.text-center
                                                    v-progress-circular(
                                                        :size='75'
                                                        :width='7'
                                                        color='#0f5b9a'
                                                        indeterminate
                                                        style="margin-top: 75px; margin-bottom: 75px;"
                                                    )

                                v-alert(v-else outlined type='info' dense border='left' style="margin: 10px 40px 10px 20px; width: 100%;")
                                    | Unable to find survey.


                v-col.col-left-divider(cols='8')
                    v-tabs(grow show-arrows light v-model="currentTab")
                        v-tab(v-for='(tab, i) in currentTabs' :key='tab.key' :href="tab.href" @change="changeTab(tab)" style="font-size: 13px;")
                            | {{ tab.name }}
                        v-menu(v-if='moreTabs.length > 0' bottom left)
                            template(v-slot:activator='{ on }')
                                v-btn.align-self-center.mr-4(text v-on='on' color='rgba(0, 0, 0, 0.54)')
                                    | more
                                    v-icon(right) mdi-menu-down
                            v-list.grey.lighten-3
                                v-list-item(v-for='(tab, i) in moreTabs' :key='tab.key' @click='selectMoreTab(tab, i)')
                                    | {{ tab.name }}

                    v-tabs-items
                        div(style="margin-top: 15px;")

                            //- Call Legs - call_legs
                            CallLegs(
                                v-if="currentTab === 'call_legs'"
                                :conference="conference"
                                :hide-download-buttons="hideDownloadButtons"
                                :is-loading="isLoading"
                            )

                            //- Call Steps - call_steps
                            CallSteps(
                                v-if="currentTab === 'call_steps'"
                                :conference="conference"
                                :is-loading="isLoading"
                            )

                            //- Call Transcript = call_transcription
                            CallTranscription(
                                v-if="currentTab === 'call_transcription'"
                                :call-transcription="callTranscription"
                                :conference="conference"
                                :is-loading="isLoadingCallTransactions"
                            )


                            //- Call Transfers - call_transfers
                            CallTransfers(
                                v-if="currentTab === 'call_transfers'"
                                :conference="conference"
                                :is-loading="isLoading"
                            )

                            //- Customer - customer
                            div.mx-lg-auto(v-if="currentTab === 'customer'" width="100%")
                                //- v-toolbar.volie-v-toolbar-color(dark)
                                //-     v-toolbar-title Customer

                                v-card-text(v-if="validCustomer")
                                    v-simple-table
                                        template(v-slot:default)
                                            tbody
                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Key
                                                        template(v-if="!hideDownloadButtons")
                                                            router-link(:to="{ name: 'customers_show', params: { customer_key: conference.customer_key } }" target="_blank") {{ conference.customer_key }}
                                                        template(v-else)
                                                            | {{ conference.customer_key ? conference.customer_key : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Name
                                                        | {{ conference.customer_full_name ? conference.customer_full_name : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Email Address 1
                                                        | {{ conference.customer_email_address_one ? conference.customer_email_address_one : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Email Address 2
                                                        | {{ conference.customer_email_address_two ? conference.customer_email_address_two : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Cell Phone
                                                        template(v-if="conference.customer_cell_phone && conference.customer_cell_phone.length > 0")
                                                            | {{ conference.customer_cell_phone | tel }}
                                                        template(v-else) ---
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Home Phone
                                                        template(v-if="conference.customer_home_phone && conference.customer_home_phone.length > 0")
                                                            | {{ conference.customer_home_phone | tel }}
                                                        template(v-else) ---

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Work Phone
                                                        template(v-if="conference.customer_work_phone && conference.customer_work_phone.length > 0")
                                                            | {{ conference.customer_work_phone | tel }}
                                                        template(v-else) ---
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Address 1
                                                        | {{ conference.customer_address_one ? conference.customer_address_one : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Address 2
                                                        | {{ conference.customer_address_two ? conference.customer_address_two : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 City / Region
                                                        | {{ conference.customer_city_region ? conference.customer_city_region : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 State / Province
                                                        | {{ conference.customer_state_province ? conference.customer_state_province : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Postal Code
                                                        | {{ conference.customer_postal_code ? conference.customer_postal_code : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Vehicle Key
                                                        | {{ conference.customer_vehicle_key ? conference.customer_vehicle_key : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Key
                                                        | {{ conference.vehicle_key ? conference.vehicle_key : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model Year
                                                        | {{ conference.vehicle_model_year ? conference.vehicle_model_year : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Make
                                                        | {{ conference.vehicle_make ? conference.vehicle_make : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model
                                                        | {{ conference.vehicle_model ? conference.vehicle_model : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle VIN
                                                        | {{ conference.vehicle_vin ? conference.vehicle_vin : '---' }}

                                                tr
                                                    td(style="max-width: 50%; width: 50%;")
                                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Trim
                                                        | {{ conference.vehicle_trim ? conference.vehicle_trim : '---' }}
                                                    td(style="max-width: 50%; width: 50%;")

                                div(v-else-if="!isLoading" style="padding: 10px;")
                                    v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                                        | There is no customer for this conference

                                .text-center(v-else)
                                    v-progress-circular(
                                        :size='100'
                                        :width='10'
                                        color='#0f5b9a'
                                        indeterminate
                                        style="margin-top: 75px; margin-bottom: 75px;"
                                    )

                            //- Events - events
                            Events(
                                v-if="currentTab === 'events'"
                                :call-events="callEvents"
                                :conference-events="conferenceEvents"
                                :is-loading-call-events="isLoadingCallEvents"
                                :is-loading-conference-events="isLoadingConferenceEvents"
                            )

                            //- Follow ups - follow_ups
                            FollowUps(
                                v-if="currentTab === 'follow_ups'"
                                :follow-ups="followUps"
                                :is-loading="isLoadingFollowUps"
                            )

                            //- Hot Alerts
                            HotAlerts(
                                v-if="currentTab === 'hot_alerts'"
                                :is-loading="isLoadingHotAlerts"
                                :alert-type-surveys="alertTypeSurveys"
                            )

                            //- Inbound Insights - inbound_insights
                            div.mx-lg-auto#inbound-insights-container(v-if="currentTab === 'inbound_insights'" width="100%")
                                InboundInsights(
                                    :call-call-queue="callCallQueue"
                                    :is-loading="isLoadingInboundInsights"
                                )

                            //- Missed Call - missed_call
                            v-container.mx-auto(v-if="currentTab === 'missed_call'")
                                MissedCall(
                                    :hide-download-buttons="hideDownloadButtons"
                                    :is-loading="isLoadingVoiceMailRecording"
                                    :voice-mail-recording="voiceMailRecording"
                                )

                            //- Transactions - campaign_communication_transactions
                            CampaignCommunicationTransactions(
                                v-if="currentTab === 'campaign_communication_transactions'"
                                :campaign-communication-transactions="campaignCommunicationTransactions"
                                :is-loading="isLoadingCampaignCommunicationTransactions"
                            )

                            //- Web Rtc - web_rtc
                            div.mx-lg-auto#web-rtc-container(v-if="currentTab === 'web_rtc'" width="100%")
                                WebRtcs(
                                    :is-loading="isLoadingWebRtcSession"
                                    :web-rtc-session="webRtcSession"
                                )

                            //- Webhook Events - webhook_events
                            WebhookEvents(
                                v-if="currentTab === 'webhook_events'"
                                :webhook-events="webhookEvents"
                                :is-loading="isLoadingWebhookEvents"
                            )

                    //- HIDE on shareable
                    .text-left(style="margin-top: 10px;")
                        v-btn.ma-2(color="#0f5b9a" outlined ripple to="/calls")
                            v-icon mdi-arrow-left
                            | Back
                                            
</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CallLegs from '@/components/conferences/call_legs/index.vue';
    import CallSteps from '@/components/conferences/call_steps/index.vue';
    import CallTransfers from '@/components/conferences/call_transfers/index.vue';
    import CallTranscription from '@/components/conferences/call_transcriptions/index.vue';
    import CampaignCommunicationTransactions from '@/components/conferences/campaign_communication_transactions/index.vue';
    import Events from '@/components/conferences/events/index.vue';
    import FollowUps from '@/components/conferences/follow_ups/index.vue';
    import HotAlerts from '@/components/conferences/hot_alerts/index.vue';
    import InboundInsights from '@/components/conferences/inbound_insights/show.vue';
    import MissedCall from '@/components/voice_mail_recordings/details.vue';
    import Scorecards from '@/components/conferences/scorecards/index.vue';
    import SharedLinksDialog from '@/components/shared_links/dialog.vue';
    import SurveyForm from '@/components/survey_template_questions/survey_form.vue';
    import TableColumnSort from "@/components/shared/table_column_sort.vue";
    import TableFixedHeader from "@/components/shared/table_fixed_header.vue";
    import WebRtcs from '@/components/conferences/web_rtcs/show.vue';
    import WebhookEvents from '@/components/webhook_events/index.vue';

    // Data
    import { 
        CreateOrUpdateSurveyAnswers,
        FindConference,
        FindConferenceCallCallQueue,
        FindConferenceWebRtcSession,
        FindVoiceMailRecording,
        GetAlertTypeSurveys,
        GetCallEvents,
        GetRecordingCallTranscription,
        GetConferenceCampaignCommunicationTransactions,
        GetConferenceEvents,
        GetConferenceFollowUps,
        GetWebhookEvents,
        UpdateSurveyAttempt,
    } from "@/data";

    export default  Vue.extend({
        data() {
            return {
                alertTypeSurveys: [],
                callCallQueue: null,
                callEvents: [],
                callTranscription: null,
                campaignCommunicationTransactions: [],
                conference: null,
                conferenceEvents: [],
                currentTab: 'call_legs',
                currentTabs: [],
                followUps: [],
                isLoading: false,
                isLoadingCallEvents: false,
                isLoadingCampaignCommunicationTransactions: false,
                isLoadingCallTransactions: false,
                isLoadingConferenceEvents: false,
                isLoadingFollowUps: false,
                isLoadingHotAlerts: false,
                isLoadingInboundInsights: false,
                isLoadingVoiceMailRecording: false,
                isLoadingWebRtcSession: false,
                isLoadingWebhookEvents: false,
                moreTabs: [],
                panelDetailsOpen: true,
                panelDispositionOpen: true,
                panelInsightOpen: true,
                panelKeysOpen: false,
                panelScorecardOpen: true,
                panelSurveyOpen: true,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                tabs: [
                    { name: "Call Legs", key: "call_legs", href: "#call_legs"},
                    { name: "Call Steps", key: "call_steps", href: "#call_steps"},
                    { name: "Call Transcription", key: "call_transcription", href: "#call_transcription"},
                    { name: "Call Transfers", key: "call_transfers", href: "#call_transfers"},
                    { name: "Customer", key: "customer", href: "#customer"},
                    { name: "Events", key: "events", href: "#events"},
                    { name: "Follow Ups", key: "follow_ups", href: "#follow_ups"},
                    { name: "Hot Alerts", key: "hot_alerts", href: "#hot_alerts"},
                    { name: "Inbound Insights", key: "inbound_insights", href: "#inbound_insights"},
                    { name: "Missed Call", key: "missed_call", href: "#missed_call"},
                    { name: "Transactions", key: "campaign_communication_transactions", href: "#campaign_communication_transactions"},
                    { name: "Web Rtc", key: "web_rtc", href: "#web_rtc"},
                    { name: "Webhook Events", key: "webhook_events", href: "#webhook_events"},
                ],
                voiceMailRecording: null,
                webRtcSession: null,
                webhookEvents: [],
            }
        },
        methods: {
            changeTab(tab) {
                if (!(tab?.key?.length > 0)) {
                    return;
                }

                this.currentTab = tab.key;
                
                switch(tab.key) {
                    case 'call_transcription':
                        if(!(this.callTranscription?.call_transcription_id > 0)) {
                            this.getCallTranscriptionFromRecordingVid();
                        }
                        break;
                    case 'campaign_communication_transactions':

                        // if campaign communication transactions has not already been loaded
                        if (!(this.campaignCommunicationTransactions?.length > 0)) {
                            this.getConferenceCampaignCommunicationTransactions();
                        }

                        break;
                    case 'events':

                        // if call events have not already been loaded
                        if (!(this.callEvents?.length > 0)) {
                            this.getCallEvents();
                        }

                        // if conference events have not already been loaded
                        if (!(this.conferenceEvents?.length > 0)) {
                            this.getConferenceEvents();
                        }

                        break;
                    case 'follow_ups':

                        // if follow ups has not already been loaded
                        if (!(this.followUps?.length > 0)) {
                            this.getConferenceFollowUps();
                        }

                        break;
                    case 'hot_alerts':

                        // if hot alerts has not already been loaded
                        if (!(this.alertTypeSurveys?.length > 0)) {
                            this.getAlertTypeSurveys();
                        }

                        break;
                    case 'inbound_insights':

                        // if call call queue has not already been loaded
                        if (!(this.callCallQueue?.call_call_queue_id > 0)) {
                            this.findCallCallQueue();
                        }

                        break;
                    case 'missed_call':

                        // if voice mail recording has not already been loaded
                        if (!(this.voiceMailRecording?.voice_mail_recording_key?.length > 0)) {
                            this.findVoiceMailRecording();
                        }

                        break;
                    case 'web_rtc':

                        // if web rtc session has not already been loaded
                        if (!(this.webRtcSession?.vid?.length > 0)) {
                            this.findWebRtcSession();
                        }

                        break;
                    case 'webhook_events':

                        // if web webhook events has not already been loaded
                        if (!(this.webhookEvents?.length > 0)) {
                            this.getWebhookEvents();
                        }

                        break;
                }
            },
            createOrUpdateSurveyAnswers() {
                if (!(this.conference?.survey_id > 0 && this.conference?.survey_answers?.length > 0)) {
                    return;
                }

                const options = {
                    survey_id: this.conference.survey_id,
                    survey_answers: this.conference.survey_answers,
                }

                CreateOrUpdateSurveyAnswers(options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        this.showSnackbar("green", "Successfully saved survey answers!");
                    } else {
                        this.showSnackbar("error", "Failed to save survey answers!");
                    }
                });            
            },
            findCallCallQueue() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                const options = {
                    include_call_call_queue_activities: true,
                    include_ignore_calls: true,
                }

                this.isLoadingInboundInsights = true;

                FindConferenceCallCallQueue(conferenceKey, options).then((response) => {
                    if (response?.Data?.call_call_queue?.call_call_queue_id > 0) {
                        this.callCallQueue = response.Data.call_call_queue;
                    }

                    this.isLoadingInboundInsights = false;
                });
            },
            findConference() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                this.isLoading = true;

                // reset conference to null for when needing to refresh conference record
                this.conference = null;
                FindConference(conferenceKey).then((response) => {
                    if (response?.Data?.conference?.conference_key?.length > 0) {
                        this.conference = response.Data.conference;
                    }

                    this.isLoading = false;
                });
            },
            findVoiceMailRecording() {
                const voiceMailRecordingKey = this.conference?.voice_mail_recording_key || this.$route?.query?.missed_call_vid;
                if (!(voiceMailRecordingKey?.length > 0)) {
                    return;
                }

                const options = {
                    include_return_call: true,
                }

                this.isLoadingVoiceMailRecording = true;

                FindVoiceMailRecording(voiceMailRecordingKey, options).then((response) => {
                    if (response?.Data?.voice_mail_recording?.voice_mail_recording_key?.length > 0) {
                        this.voiceMailRecording = response.Data.voice_mail_recording;
                    }

                    this.isLoadingVoiceMailRecording = false;
                });
            },
            findWebRtcSession() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                const options = {
                    include_participants: true,
                }

                this.isLoadingWebRtcSession = true;

                FindConferenceWebRtcSession(conferenceKey, options).then((response) => {
                    if (response?.Data?.web_rtc_session?.vid?.length > 0) {
                        this.webRtcSession = response.Data.web_rtc_session;
                    }

                    this.isLoadingWebRtcSession = false;
                });
            },
            getAlertTypeSurveys() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                const options = {
                    conference_key: conferenceKey,
                    include_alerts: true,
                }

                this.isLoadingHotAlerts = true;

                GetAlertTypeSurveys(options).then((response) => {
                    if (response?.Data?.alert_type_surveys?.length > 0) {
                        this.alertTypeSurveys = response.Data.alert_type_surveys;
                    }

                    this.isLoadingHotAlerts = false;
                });
            },
            getCallEvents() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                const options = {
                    conference_key: conferenceKey,
                }

                this.isLoadingCallEvents = true;

                GetCallEvents(options).then((response) => {
                    if (response?.Data?.call_events?.length > 0) {
                        this.callEvents = response.Data.call_events;
                    }

                    this.isLoadingCallEvents = false;
                });
            },
            getCallTranscriptionFromRecordingVid() {
                if(!(this.conference?.recording_key?.length > 0)) {
                    this.showSnackbar("red", "This conference does not have a recording");
                    return;
                }

                // if we have conference && we have a recording vid
                this.isLoadingCallTransactions = true;
                GetRecordingCallTranscription(this.conference.recording_key).then((response) => {
                    if (response?.Data?.call_transcription?.vid?.length > 0) {
                        this.callTranscription = response.Data.call_transcription;
                    }

                    this.isLoadingCallTransactions = false;
                });
            },
            getConferenceCampaignCommunicationTransactions() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                this.isLoadingCampaignCommunicationTransactions = true;

                GetConferenceCampaignCommunicationTransactions(conferenceKey, {}).then((response) => {
                    if (response?.Data?.campaign_communication_transactions?.length > 0) {
                        this.campaignCommunicationTransactions = response.Data.campaign_communication_transactions;
                    }

                    this.isLoadingCampaignCommunicationTransactions = false;
                });
            },
            getConferenceEvents() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                const options = {
                    conference_key: conferenceKey,
                }

                this.isLoadingConferenceEvents = true;

                GetConferenceEvents(options).then((response) => {
                    if (response?.Data?.conference_events?.length > 0) {
                        this.conferenceEvents = response.Data.conference_events;
                    }

                    this.isLoadingConferenceEvents = false;
                });
            },
            getConferenceFollowUps() {
                const conferenceKey = this.conferenceVid;
                if (!(conferenceKey?.length > 0)) {
                    return;
                }

                this.isLoadingFollowUps = true;

                GetConferenceFollowUps(conferenceKey, {}).then((response) => {
                    if (response?.Data?.follow_ups?.length > 0) {
                        this.followUps = response.Data.follow_ups;
                    }

                    this.isLoadingFollowUps = false;
                });
            },
            getWebhookEvents() {
                const conferenceVid = this.conferenceVid;
                if (!(conferenceVid?.length > 0)) {
                    return;
                }

                const options = {
                    conference_vid: conferenceVid,
                }

                this.isLoadingWebhookEvents = true;

                GetWebhookEvents(options).then((response) => {
                    if (response?.Data?.webhook_events?.length > 0) {
                        this.webhookEvents = response.Data.webhook_events;
                    }

                    this.isLoadingWebhookEvents = false;
                });
            },
            initTabs() {
                // hide tabs
                if (!this.currentUserIsSuperAdmin) {
                    this.tabs = this.tabs.filter(x => x.key !== 'call_steps');
                }

                this.currentTabs = this.tabs.filter(x => x.key === 'call_transfers' || x.key === 'call_legs' || x.key === 'customer');
                this.moreTabs = this.tabs.filter(x => !(x.key === 'call_transfers' || x.key === 'call_legs' || x.key === 'customer'));

                if (this.$route?.query?.missed_call_vid?.length > 0) {
                    const tabIdx = this.moreTabs.findIndex(x => x.key === "missed_call")
                    this.selectMoreTab(this.moreTabs[tabIdx], tabIdx)
                }
            },
            isSurveySaveEligible() {

                // Init
                let eligible = true;

                // Verify answers
                if (this.conference?.survey_answers?.length > 0) {

                    // Loop through answers and if we find an answer that is required, applicable but no answer, set eligible to false
                    for (let i = 0; i < this.conference.survey_answers.length; i++) {

                        // Init
                        const surveyAnswer = this.conference.survey_answers[i];
                        if (!surveyAnswer) {
                            continue;
                        }

                        // Determine if we should stop survey from saving until they've answered all of the required questions
                        if (surveyAnswer.required === true && surveyAnswer.is_applicable === true && !(surveyAnswer.answer?.length > 0)) {
                            eligible = false;
                            break;
                        }
                    }
                }

                return eligible;
            },
            selectMoreTab(tab, idx) {
                if (!tab || !(idx >= 0)) {
                    return;
                }

                // splice in selected tab to v-tabs and return removed tab
                const removed = this.currentTabs.splice(2, 1, tab);

                // splice in tab moving from currentTabs to moreTabs menu
                this.moreTabs.splice(idx, 1, removed[0]);

                // sort
                this.moreTabs.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                });

                // set currentTab
                this.changeTab(tab);
                
            },
            setSurveyAnswer(inputSurveyAnswer) {

                // Verify
                if (!this.conference?.survey_answers || !inputSurveyAnswer || !(inputSurveyAnswer?.survey_template_id > 0) || !(inputSurveyAnswer.survey_template_question_id > 0) || inputSurveyAnswer?.survey_template_id !== this.conference?.survey_template_id) {
                    return;
                }

                // Determine if we have the answer by question in the array already, if so, update fields. Otherwise push onto array
                const idx = this.conference.survey_answers.findIndex(i => i.survey_template_question_id === inputSurveyAnswer.survey_template_question_id);
                if (idx >= 0) {

                    // Init
                    const existingSurveyAnswer = this.conference.survey_answers[idx];
                    if (!existingSurveyAnswer) {
                        return;
                    }

                    // Set updates
                    existingSurveyAnswer.survey_answer = inputSurveyAnswer.survey_answer;
                    existingSurveyAnswer.is_applicable = inputSurveyAnswer.is_applicable;
                    existingSurveyAnswer.survey_template_question_option_id = inputSurveyAnswer.survey_template_question_option_id;
                } else {
                    this.conference.survey_answers.push({ ...inputSurveyAnswer});
                }
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            updateSurveyAttempt() {
                if (!(this.conference?.survey_attempt_id > 0 && this.conference?.disposition_id > 0)) {
                    return;
                }

                const options = {
                    survey_attempt_id: this.conference.survey_attempt_id,
                    disposition_id: this.conference.disposition_id,
                    notes: this.conference.survey_attempt_notes?.length > 0 ? this.conference.survey_attempt_notes : "null",
                }

                UpdateSurveyAttempt(options.survey_attempt_id, options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        this.showSnackbar("green", "Successfully updated survey attempt disposition!");

                        // reload conference details
                        this.findConference();

                    } else {
                        this.showSnackbar("error", "Failed to update survey attempt disposition!");
                    }
                });
            },
        },
        computed: {
            conferenceInsightsChipItems() {
                if (!this.validConference) {
                    return [];
                }

                const items = [];

                if (this.conference.is_dropped) {
                    items.push("Agent Dropped")
                }
                if (this.conference.is_appointment) {
                    items.push("Appointment")
                }
                if (this.conference.is_affinity) {
                    items.push("Affinity")
                }
                if (this.conference.is_appointment_cancel) {
                    items.push("Appointment Canceled")
                }
                if (this.conference.is_appointment_reschedule) {
                    items.push("Appointment Rescheduled")
                }
                if (this.conference.is_bad_number) {
                    items.push("Bad Number")
                }
                if (this.conference.is_contact) {
                    items.push("Contact")
                }
                if (this.conference.campaign_customer_completed) {
                    items.push("Customer Completed")
                }
                if (this.conference.customer_dropped) {
                    items.push("Customer Dropped")
                }
                if (this.conference.is_call_disconnect) {
                    items.push("Disconnect")
                }
                if (this.conference.is_dnc) {
                    items.push("DNC")
                }
                if (this.conference.is_final_disposition) {
                    items.push("Final Disposition")
                }
                if (this.conference.is_hot_alert) {
                    items.push("Hot Alert")
                }
                if (this.conference.is_ignore) {
                    items.push("Ignored Call")
                }
                if (this.conference.is_instant_voicemail) {
                    items.push("Instant Voicemail")
                }
                if (this.conference.is_manual_call) {
                    items.push("Manual Call")
                }
                if (this.conference.is_max_hold_duration_redirect) {
                    items.push("Max Hold Duration Redirect")
                }
                if (this.conference.voice_mail_recording_purpose) {
                    items.push("Missed Call")
                }
                if (this.conference.is_lead) {
                    items.push("New Lead")
                }
                if (this.conference.is_survey) {
                    items.push("Survey")
                }
                if (this.conference.is_suspicious) {
                    items.push("Suspicious")
                }
                if (this.conference.is_test_call) {
                    items.push("Test Call")
                }
                if (this.conference.is_unreachable) {
                    items.push("Unreachable")
                }
                if (this.conference.is_voice_mail_recording) {
                    items.push("Voicemail")
                }

                return items;
            },
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            eligibleToSaveSurveyAnswers() {
                return this.conference?.survey_answers?.length > 0 && this.isSurveySaveEligible();
            },
            eligibleToSaveSurveyAttempt() {
                return this.conference?.disposition_id > 0;
            },
            railsVolieBaseUrl() {
                return VolieState.baseRailsUrl();
            },
            recordingUrl() {
                return this.conference?.recording_url;
            },
            renderSharedLinks() {
                return this.currentUser?.account?.shared_links_enabled === true;
            },
            validCallLegs() {
                return this.conference?.calls?.length > 0;
            },
            validCallTranscription() {
                return this.callTranscription?.vid?.length > 0;
            },
            validCallTransfers() {
                return this.conference?.call_transfers?.length > 0;
            },
            validConference() {
                return this.conference?.conference_key?.length > 0;
            },
            validCustomer() {
                return this.conference?.customer_key?.length > 0;
            },
            validSurvey() {
                return this.conference?.survey_id > 0;
            },
            validSurveyAnswers() {
                return this.conference?.survey_answers?.length > 0;
            },
            validSurveyAttempt() {
                return this.conference?.survey_attempt_id > 0;
            },
        },
        components: {
            CallLegs,
            CallSteps,
            CallTransfers,
            CallTranscription,
            CampaignCommunicationTransactions,
            Events,
            FollowUps,
            HotAlerts,
            InboundInsights,
            MissedCall,
            Scorecards,
            SharedLinksDialog,
            SurveyForm,
            TableColumnSort,
            TableFixedHeader,
            WebRtcs,
            WebhookEvents,
        },
        props: {
            conferenceVid: String,
            hideCreateUpdateDestroyButtons: Boolean,
            hideDownloadButtons: Boolean,
        },
        created() {
            this.findConference();
            this.initTabs();
            return;
        },
    });
</script>

<style lang="scss" scoped>

    #app {
        .col-right-divider {
            border-right: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px 20px 10px 20px;
        }

        .col-left-divider {
            border-left: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px 20px 10px 20px;
        }
    }
</style> 
